import types from './types'


const INITIAL_STATE = {
  progress: null,
  loading: false,
  error: ''
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PROGRESS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.PROGRESS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.PROGRESS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload
      }
    default:
      return state
  }
}

export default reducer