import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';

import DialogTitleWithCloseIcon from '../../commons/DialogTitleWithCloseIcon';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: theme.spacing(1),
  }
}))(MuiDialogContent)

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ObservationTimeDialogInner = props => {
  const { onClose, value: valueProp, open, classes, ...other } = props;
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleKeyUp = event => {
    console.log('handleKeyUp', event.key);
    if (event.key === 'Enter') {
      onClose(value);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value !== '' ? new Date(event.target.value).toLocaleString() : null;
    setValue(value);
  };

  const formattedValue = value ? moment(value, 'DD.MM.YYYY, HH:mm:ss').format('YYYY-MM-DDTHH:mm') : ''
  return (
    <Dialog onClose={handleCancel} aria-labelledby="observation-time-dialog-title" open={open} maxWidth="xs" {...other}>
      <DialogTitleWithCloseIcon id="observation-time-dialog-title" onClose={handleCancel}>
        Beobachtungszeitpunkt
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <TextField
          value={formattedValue}
          type="datetime-local"
          onChange={handleChange}
          onKeyPress={handleKeyUp}
        ></TextField>
        <small>Wenn kein Beobachtungszeitpunkt gesetzt wird, wird der Erstellzeitpunkt der Beboachtung verwendet.</small>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Abbrechen
        </Button>
        <Button onClick={handleOk} color="primary">
          Übernehmen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function ObservationTimeDialog(props) {
  return <ObservationTimeDialogInner TransitionComponent={Transition} {...props}/>
}
