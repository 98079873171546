import fetch from '../fetch';
import recordsApi from '../records/api';
import actions from './actions';

const mongoUrl = process.env.REACT_APP_BACKEND_URL

export const index = (studentId, taskIds) => (dispatch, getState) => {
  const search = taskIds && taskIds.length ? `?filter[case_id]=${taskIds.join(',')}` : ''
  const url = `${mongoUrl}/progress/${studentId}${search}`
  const user = getState().user.user

  dispatch(actions.progressFetchRequest())
  return fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => {
      if (!response.ok) throw new Error('Fehlgeschlagen!')
      return response.json()
    })
    .then(progress => {
      const mappedProgress = {
        ...progress,
        records: (progress.records || []).map(recordsApi.mapRecordFromApi)
      }
      dispatch(actions.progressFetchSuccess(mappedProgress))
      return mappedProgress
    })
    .catch(error => {
      dispatch(
        actions.progressFetchFailure(error)
      )
    })
}

export default { index }
