import * as moment from 'moment';

import epaJson from '../../assets/epas';
import types from './types';

const INITIAL_STATE = {
  index: 0,
  //-----
  record: {
    id: -1,
    role: null,
    globalRating: '',
    epas: [],
    student: null,
    studentId: null,
  },
  pastRecords: [],
  loading: false,
  error: '',
  success: null,
  snackMessage: '...',
  uploading: false,
  validationErrors: [],
  validationErrorsVisible: false,
  epas: [],
  selectedEpa: null,
  selectedIndex: null, // index of epas
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TASK_EPAS:
      const sortedEpas = action.payload
        .map(id => epaJson.find(epa => epa.id === id))
        .sort((a, b) => a.id - b.id)
      return {
        ...state,
        epas: sortedEpas,
        selectedEpa: sortedEpas[0],
        selectedIndex: 0,
        }
    case types.SELECT_EPA:
      return {
        ...state,
        selectedEpa: state.epas[action.payload],
        selectedIndex: action.payload
      }
    case types.RECORD_GET_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.RECORD_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.RECORD_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        record: action.payload || INITIAL_STATE.record,
        validationErrors: [],
        validationErrorsVisible: false,
      }
    case types.RECORD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.RECORD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.RECORD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case types.RECORD_HISTORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      }
    case types.RECORD_HISTORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.RECORD_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pastRecords: action.payload
      }
    case types.RECORD_STORE_REQUEST:
      return {
        ...state,
        uploading: true,
        success: null,
        snackMessage: 'Beobachtung wird erstellt...',
        error: ''
      }
    case types.RECORD_STORE_SUCCESS:
      return {
        ...state,
        uploading: false,
        success: true,
        snackMessage: 'Beobachtung wurde gespeichert. Eine neue leere Beobachtung wurde geladen!',
        record: INITIAL_STATE.record,
        validationErrors: [],
        validationErrorsVisible: false,
        selectedIndex: 0,
        selectedEpa: state.epas && state.epas[0],
      }
    case types.RECORD_STORE_FAILURE:
      return {
        ...state,
        uploading: false,
        success: false,
        snackMessage: 'Beobachtung konnte nicht gespeichert werden...',
        comment: null,
        error: action.payload
      }
    case types.RECORD_UPDATE_REQUEST:
      return {
        ...state,
        uploading: true,
        success: null,
        snackMessage: 'Beobachtung wird überschrieben...',
        error: ''
      }
    case types.RECORD_UPDATE_SUCCESS:
      return {
        ...state,
        uploading: false,
        success: true,
        snackMessage: 'Änderungen wurden erfolgreich gespeichert!',
        record: action.payload
      }
    case types.RECORD_UPDATE_FAILURE:
      return {
        ...state,
        uploading: false,
        success: false,
        snackMessage: 'Änderungen konnten nicht gespeichert werden...',
        comment: null,
        error: action.payload
      }
    case types.RECORD_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        success: null,
        snackMessage: 'Löschvorgang läuft...',
        error: ''
      }
    case types.RECORD_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        snackMessage: 'Löschvorgang erfolgreich!'
      }
    case types.RECORD_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        snackMessage: 'Löschvorgang fehlgeschlagen...',
        error: action.payload
      }
    case types.SET_VALIDATION_ERRORS_VISIBILITY:
      return {
        ...state,
        validationErrorsVisible: !!action.payload
      }
    case types.SET_ROLE: {
      const updatedRecord = {
        ...state.record,
        role: action.payload,
        isDirty: true,
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }
    }
    case types.SET_STUDENT: {
      const updatedRecord = {
        ...state.record,
        student: action.payload,
        studentId: action.payload ? action.payload.id : null,
        isDirty: true,
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }
    }
    case types.SET_SUPERVISION_LEVEL: {
      const updatedRecord = {
        ...state.record,
        epas: setSupervisionLevelForSelectedEpa(action.payload, state),
        isDirty: true,
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }
    }
    case types.SET_GLOBAL_RATING: {
      const updatedRecord = {
        ...state.record,
        globalRating: action.payload,
        isDirty: true,
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }
    }
    case types.SET_OBSERVED_AT: {
      const updatedRecord = {
        ...state.record,
        observedAt: action.payload,
        isDirty: true,
      }
      if (action.payload) {
        updatedRecord.observed_at = moment(action.payload, 'DD.MM.YYYY, hh:mm:ss').valueOf()
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }
    }
    case types.SELECT_BEHAVIOUR:
      const updatedRecord = {
        ...state.record,
        epas: selectBehaviourForSelectedEpa(action.payload, state),
        isDirty: true,
      }
      return {
        ...state,
        record: updatedRecord,
        validationErrors: validateRecord(updatedRecord, state)
      }

    default:
      return state
  }
}

export const validateRecord = (record, state) => {
  const errors = [];
  if (!record) {
    return errors;
  }
  if (!record.role && state.epas.find(taskEpa => taskEpa.id === 15)) {
    errors.push({itemTypeId: 15, property: 'role', error: 'missing-value'})
  }

  const taskEpas = state.epas
    .map(taskEpa => {
      const recordEpa = record.epas.find(epa => epa.id === taskEpa.id)
      const recordCompetencies = (recordEpa && recordEpa.competencies) || []
      return {
        ...taskEpa,
        ...(recordEpa || {}),
        competencies: taskEpa.competencies.map(competencyDefinition => ({
          ...competencyDefinition,
          ...(recordCompetencies.find(competency => competency.competencyId === competencyDefinition.id) || {})
        }))
      }
    })

  errors.push(...taskEpas
    .filter(epa => epa.id < 14 && epa.supervision === undefined)
    .map(epa => ({itemTypeId: epa.id, property: 'supervision', error: 'missing-value'}))
  )

  errors.push(...taskEpas
    .filter(epa => epa.id === 14 || epa.id === 15)
    .map(epa => epa.competencies.map(competency => ({...competency, epaId: epa.id})))
    .reduce((prev, curr) => [...prev, ...curr], [])
    .filter(competency => !competency.value)
    .filter(competency => competency.epaId !== 15 || competency.id !== 3)
    .map(competency => ({itemTypeId: competency.epaId, property: 'competency', propertyId: competency.id, error: 'missing-value'}))
  )
  return errors;
}

const setSupervisionLevelForSelectedEpa = (supervisionLevel, state) => {
  return withCurrentEpa(state).map(epa => ({
      ...epa,
      supervision: epa.id === state.selectedEpa.id ? supervisionLevel : epa.supervision
    })
  );
}

const selectBehaviourForSelectedEpa = (behaviour, state) => {
  return withCurrentEpa(state).map(epa => {
    if (epa.id !== state.selectedEpa.id) {
      return epa;
    }
    return {
      ...epa,
      competencies: addOrSetCompetencyLevel(behaviour, epa.competencies || []),
      selectionLog: addSelection(behaviour, epa.selectionLog || [])
    }
  })
}

const withCurrentEpa = (state) => {
  if (state.record.epas.find(epa => epa.id === state.selectedEpa.id)) {
    return state.record.epas;
  }
  return [
    ...state.record.epas,
    { id: state.selectedEpa.id }
  ]
}

const addSelection = (behaviour, selectionLog) => {
  return [
    ...selectionLog,
    { ...behaviour, timestamp: Date.now() }
  ]
}

const addOrSetCompetencyLevel = (behaviour, competencies) => {
  const currentCompetencyLevel = competencies.find(competency => competency.competencyId === behaviour.competencyId)

  if (!currentCompetencyLevel) {
    return [...competencies, behaviour]
  }

  if (behaviour.value === null) {
    return competencies.filter(competency => competency.competencyId !== behaviour.competencyId)
  }

  if (currentCompetencyLevel.value === behaviour.value) {
    return competencies;
  }

  return competencies.map(competency => ({
    ...competency,
    value: competency.competencyId === behaviour.competencyId ? behaviour.value : competency.value
  }))
}

export default reducer

// TODO: Should be memoized
export const selectVisibleValidationErrors = recordsState => recordsState.validationErrorsVisible ?
  recordsState.validationErrors.filter(error => error.itemTypeId === recordsState.selectedEpa.id) :
  []

export const selectSelectedCompetencies = recordState => {
  const epa = recordState.record.epas.find(epa => epa.id === recordState.selectedEpa.id);
  if (epa) {
    return epa.competencies || [];
  }
  return [];
}