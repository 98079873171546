import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import ButtonAppBar from '../../commons/AppBar';


export const PrivateRoute = ({ component: Component, header, title, module, permissions, ...rest }) => {
  const user = rest.user
  const canAccessPrivateRoute = user && user.permissions && !!user.permissions.filter(userPermission => permissions.includes(userPermission)).length

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          canAccessPrivateRoute ? (
            <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
              {header ? <ButtonAppBar location={props.location} title={title} module={module}/> : null}
              <Component {...props} />
            </div>
          ) : (
            <Redirect
              to={{
                pathname: '/about',
                state: { from: props.location }
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
