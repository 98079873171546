import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as History from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import config from './config/config';
import rootReducer from './ducks';


export const history = History.createBrowserHistory({ basename: process.env.PUBLIC_URL })

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENABLE_DEV_TOOLS === 'true') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  middleware.push(logger)
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}
console.warn = () => {}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const persistConfig = {
  key: config.reduxKey,
  storage,
  blacklist: ['task', 'room'],
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, connectRouter(history)(rootReducer))

export default () => {
  let store = createStore(
    persistedReducer,
    initialState,
    composedEnhancers
  )
  let persistor = persistStore(store)
  return { store, persistor }
}
