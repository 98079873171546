import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar/Avatar';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Assignment } from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import ListIcon from '@material-ui/icons/List';
import MenuIcon from '@material-ui/icons/Menu';
import PollIcon from '@material-ui/icons/Poll';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import { Link as RouterLink, Prompt as RouterPrompt } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import config from '../config/config';
import Subheader from '../containers/form/Subheader';
import { userOperations } from '../ducks/users';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    height: '64px'
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    paddingLeft: theme.spacing(2),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  sectionDesktop: {
    display: 'flex'
  },
  user: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4)
    }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1)
  },
  thinHeader: {
    lineHeight: '1',
  },
  lightText: {
    color: '#e0f2f194',
  },
  lightBackground: {
    backgroundColor: '#e0f2f194',
  },
  denseIcon: {
    minWidth: '35px',
  }
}))

const PrimarySearchAppBar = props => {
  const { requiredPermissions } = config
  const classes = useStyles()
  const { recordIsDirty, pathname} = props
  const [anchorEl, setAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)
  const shouldRenderPrompt = pathname.includes('forms') && recordIsDirty;

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  function handleSignOut() {
    handleMenuClose()
    props.signOut()
  }

  function isAllowedToSeeMenuItem(permissions) {
    return props.user && props.user.permissions && !!props.user.permissions.filter(userPermission => permissions.includes(userPermission)).length
  }

  const menuId = 'primary-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Grid item className={classes.user}>
        <Grid container direction={'column'} alignItems={'center'}>
          <Avatar className={classes.avatar} alt="No Image">
            {props.user.firstname.charAt(0) + props.user.lastname.charAt(0)}
          </Avatar>
          <Typography variant={'caption'}>{props.user.firstname} {props.user.lastname}</Typography>
        </Grid>
      </Grid>
      <Divider light={true}/>
      {isAllowedToSeeMenuItem(requiredPermissions.room) ?
        <MenuItem onClick={handleMenuClose} component={RouterLink} to="/rooms">
          <ListItemIcon className={classes.denseIcon}>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Raum-/Fallauswahl" />
        </MenuItem> : null
      }
      {isAllowedToSeeMenuItem(requiredPermissions.record) ?
        <MenuItem onClick={handleMenuClose} component={RouterLink} to="/records">
          <ListItemIcon className={classes.denseIcon}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Beobachtungsübersicht" />
        </MenuItem> : null
      }
      {isAllowedToSeeMenuItem(requiredPermissions.record) ?
        <MenuItem onClick={handleMenuClose} component={RouterLink} to="/observation-assignments">
          <ListItemIcon className={classes.denseIcon}>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Beobachtungsaufträge" />
        </MenuItem> : null
      }
      {isAllowedToSeeMenuItem(requiredPermissions.progressControl) ?
        <MenuItem onClick={handleMenuClose} component={RouterLink} to="/progress-control">
          <ListItemIcon className={classes.denseIcon}>
            <PollIcon />
          </ListItemIcon>
          <ListItemText primary="EPA-Dashboard" />
        </MenuItem> : null
      }
      <Divider light={true}/>
      <MenuItem onClick={handleMenuClose} component={RouterLink} to="/about">
        <ListItemIcon className={classes.denseIcon}>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="Über myEPAs" />
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon className={classes.denseIcon}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Abmelden" />
      </MenuItem>
    </Menu>
  )
  return (
    <AppBar className={classes.root} position="sticky">
      <RouterPrompt
        when={shouldRenderPrompt}
        message={_ =>
          'Wenn Sie fortfahren, werden Ihre Änderungen nicht gespeichert.'
        }
      />
      <Toolbar className={classes.grow}>
        <div>
          <Typography variant="h6" noWrap className={classes.thinHeader}>myEPAs</Typography>
          {props.module ?
            <Typography variant="subtitle2" noWrap className={classes.lightText}>{props.module}</Typography> : null
          }
        </div>
        {props.title ?
        <>
        <Divider orientation="vertical" variant="middle" className={classes.lightBackground}></Divider>
        <Typography variant="subtitle1" noWrap>{props.title}</Typography>
        </>
        : null}
        <div className={classes.grow}/>
        <div className={classes.sectionDesktop}>
          {props.pathname.includes('/forms') ? <Subheader/> : null}
          <IconButton
            aria-label="Show more"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            color="inherit"
          >
            <MenuIcon/>
          </IconButton>
        </div>
      </Toolbar>
      {renderMenu}
    </AppBar>
  )
}

const { signOut } = userOperations

const mapStateToProps = ({ user, router, record }) => ({
  user: {
    ...user.user,
    firstname: user.user.firstname || 'Kein',
    lastname: user.user.lastname || 'Name'
  },
  pathname: router.location.pathname,
  recordIsDirty: record.record && !!record.record.isDirty,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrimarySearchAppBar))
