import 'moment/locale/de';

import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid/Grid';
import Link from '@material-ui/core/Link/Link';
import Slide from '@material-ui/core/Slide/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import moment from 'moment';
import qs from 'qs';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import RecordDialog from '../../commons/record-dialog/RecordDialog';
import config from '../../config/config';
import { recordOperations } from '../../ducks/records';
import { history } from '../../redux';
import FreetextChip from './FreetextChip';
import ItemChip from './ItemChip';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TablePagination from './TablePagination';

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tableWrapper: {
    overflow: 'auto',
    flex: 1
  }
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const searchParamDefaults = {
  pageSize: 10,
  pageNumber: 1,
  orderBy: 'created_at',
  orderDirection: 'desc',
  query: '',
  filter: null,
}

class RecordsTable extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      selectedRecord: null,
      selectedItem: null,
      records: [],
      totalCount: 0,
      ...this.readFromUrlSearchParams(props.location.search)
    }

    const { requiredPermissions } = config
    this.permissions.canUpdateAllRecords = props.user.permissions.filter(userPermission => requiredPermissions.updateRecord.includes(userPermission)).length > 0
    this.permissions.canUpdateSomeRecords = props.user.permissions.filter(userPermission => requiredPermissions.createRecord.includes(userPermission)).length > 0
    this.permissions.canDeleteRecords = props.user.permissions.filter(userPermission => requiredPermissions.deleteRecord.includes(userPermission)).length > 0
  }

  search$ = new Subject()
  subscriptions = []
  permissions = {}

  componentDidMount() {
    this.subscriptions.push(
      this.search$
      .pipe(
        tap(query => this.setState({query})),
        filter(query => !query.length || query.length > 2),
        debounceTime(800),
      )
      .subscribe(query => this.writeToUrlSearchParams({query}))
    )
    this.fetchRecords();
  }

  componentWillUnmount() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props
    if (prevProps.location.search !== location.search) {
      this.setState(this.readFromUrlSearchParams(location.search))
      this.fetchRecords();
    }
  }

  readFromUrlSearchParams = searchParamsString => {
    const stateToSet = {...searchParamDefaults, ...qs.parse(searchParamsString, { ignoreQueryPrefix: true })}
    Object.keys(stateToSet).forEach(key => {
      if (Number.isInteger(searchParamDefaults[key])) {
        stateToSet[key] = +stateToSet[key]
      }
    })
    return stateToSet;
  }

  writeToUrlSearchParams = state => {
    const {location} = this.props
    const paramsToWrite = Object.keys(state)
      .filter(stateKey => Object.keys(searchParamDefaults).includes(stateKey))
      .reduce((obj, stateKey) => {
        obj[stateKey] = state[stateKey];
        return obj;
      }, {})
    const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const mergedParams = {...currentParams, ...paramsToWrite}
    // Filter out default params
    const filteredParams = Object.keys(mergedParams)
      .filter(key => mergedParams[key] !== searchParamDefaults[key])
      .reduce((obj, key) => {
        obj[key] = mergedParams[key]
        return obj
      }, {})
    const query = qs.stringify(filteredParams)
    history.push({ search: query })
  }

  writeToUrlSearchParamsAndState = state => {
    this.setState(state)
    this.writeToUrlSearchParams(state)
  }

  async fetchRecords() {
    const {fetchRecords, location} = this.props
    const {data, meta} = await fetchRecords(this.readFromUrlSearchParams(location.search))
    this.setState({
      records: this.mapRecordsToTable(data),
      pageNumber: meta.page,
      totalCount: meta.total,
    });
  }

  mapRecordsToTable = records => records
    .map(record => ({
      ...record,
      student_name: `${record.student.firstName} ${record.student.lastName}`,
      observer_name: `${record.observer.firstName} ${record.observer.lastName}`,
      room_name: (record.room && record.room.title) || '',
      case_name: record.case.title,
      user_can_update_record: this.canUpdateRecord(record),
      user_can_delete_record: this.permissions.canDeleteRecords,
    }))


  canUpdateRecord = record => {
    const { user } = this.props
    const { updateAccessDurationForObservers } = config
    if (this.permissions.canUpdateAllRecords) {
      return true
    }
    if (this.permissions.canUpdateSomeRecords) {
      const recordIsRecent = Math.abs(Date.now() - record.created_at) <= updateAccessDurationForObservers;
      const userIsObserver = record.observer_id === user.id;
      const canUpdateThisRecord = recordIsRecent && userIsObserver;
      return canUpdateThisRecord
    }
    return false
  }

  handleOrderChange = ({orderBy, orderDirection}) => this.writeToUrlSearchParamsAndState({orderBy, orderDirection})
  handleResetFilter = () => this.writeToUrlSearchParamsAndState({filter: null, query: ''})

  handlePageChange = (_, page) => this.writeToUrlSearchParamsAndState({pageNumber: page + 1})
  handleRowsPerPageChange = event => this.writeToUrlSearchParamsAndState({pageSize: event.target.value})

  handleQueryChange = event => this.search$.next(event.target.value)

  handleDetailsActionFn = (record, item) => () => this.handleDetailsAction(record, item)
  handleDetailsAction = (record, item) => this.setState({ selectedRecord: record, selectedItem: item || null })
  handleDetailsClose = () => this.setState({ selectedRecord: null, selectedItem: null })
  handleEditAction = row => history.push(`/tasks/${row.case.id}/forms/${row.id}`)
  handleDeleteAction = async row => {
    const shouldDelete = window.confirm(`Beobachtung wird unwiderruflich gelöscht. Sind Sie sicher?`)
    if (shouldDelete) {
      await this.props.destroyRecord(row)
      this.fetchRecords()
    }
  }
  handleLockAction = async row => {
    row.locked_until = [{type: 'manual', value: true}]
    await this.props.updateRecord(null, row)

    this.setState({
      records: this.state.records.map(record => {
        if (record.id !== row.id) {
          return record;
        }
        return row;
      }),
    });
  }
  handleUnlockAction = async row => {
    row.locked_until = []
    await this.props.updateRecord(null, row)
    this.setState({
      records: this.state.records.map(record => {
        if (record.id !== row.id) {
          return record;
        }
        return row;
      }),
    });
  }

  render() {
    const { classes, recordsLoading } = this.props
    const { pageSize, pageNumber, totalCount, orderBy, orderDirection, records: data, selectedRecord, selectedItem, query, filter } = this.state

    const columns = [
      {id: 'createdAt', label: 'Erstellt', orderBy: 'created_at'},
      {id: 'observedAt', label: 'Beobachtungszeitpunkt', orderBy: 'observed_at'},
      {id: 'student_name', label: 'Student*in', render: row => <StudentCell record={row}/>, orderBy: 'student.lastName', filtered: !!(filter || {}).studentId},
      {id: 'observer_name', label: 'Beobachter*in', orderBy: 'observer.lastName'},
      {id: 'case_name', label: 'Fall', render: row => <CaseCell record={row}/>, orderBy: 'case.title', filtered: !!(filter || {}).caseId},
      {id: 'room_name', label: 'Raum', orderBy: 'room.title'},
      {id: 'epas', label: 'Beobachtete EPAs', render: row => <EpasCell record={row} onDetailsAction={this.handleDetailsAction}/>},
      {id: 'locked_until', label: 'Gesperrt bis', render: row => <LockedCell record={row}/>},
    ]

    return (
      <>
        <Backdrop className={classes.backdrop} open={recordsLoading}></Backdrop>
        <div className={classes.tableWrapper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead {...{
              orderBy,
              orderDirection,
              query,
              columns,
              filter,
              onOrderChange: this.handleOrderChange,
              onQueryChange: this.handleQueryChange,
              onResetFilter: this.handleResetFilter,
            }}/>
            <TableBody {...{
              data,
              columns,
              onDetailsAction: this.handleDetailsAction,
              onEditAction: this.handleEditAction,
              onDeleteAction: this.handleDeleteAction,
              onLockAction: this.handleLockAction,
              onUnlockAction: this.handleUnlockAction,
            }}/>
          </Table>
        </div>
        <TablePagination {...{
          totalCount,
          pageNumber,
          pageSize,
          onPageChange: this.handlePageChange,
          onRowsPerPageChange: this.handleRowsPerPageChange,
        }}/>
        <RecordDialog record={selectedRecord} item={selectedItem} onClose={this.handleDetailsClose} TransitionComponent={Transition}/>
      </>
    )
  }
}

const cellStyles = theme => ({
  link: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
})

const StudentCell = withStyles(cellStyles)(({record, classes}) =>
  <Tooltip title={`Zeige die fallübergreifende Lernfortschrittskontrolle für ${record.student_name}`}>
    <Link className={classes.link} onClick={() => history.push(`/progress-control/${record.student.id}`)}>
      {record.student_name}
    </Link>
  </Tooltip>)

const CaseCell = withStyles(cellStyles)(({record, classes}) =>
  <Tooltip title={`Zeige die Lernfortschrittskontrolle für ${record.student_name} zum MedForGe-Fall "${record.case_name}"`}>
    <Link className={classes.link} onClick={() => history.push(`/progress-control/${record.student.id}?tasks=${record.case.id}`)}>
      {record.case_name}
    </Link>
  </Tooltip>)

const EpasCell = ({record, onDetailsAction}) => {
  const handleDetailsActionFn = (record, item) => () => onDetailsAction(record, item)
  return <Grid container direction={'row'}>
    {record.epas
      .sort((a, b) => a.id - b.id)
      .map(epa => <ItemChip key={epa.id} record={record} recordItem={epa} onClick={handleDetailsActionFn(record, epa.id)}/>
    )}
    {record.globalRating ? <FreetextChip message={record.globalRating}/> : null}
  </Grid>
}

const LockedCell = ({record}) => {
  if (!record.locked_until || !record.locked_until.length) {
    return <>-</>
  }
  const manualCondidition = record.locked_until.find(condition => condition.type === 'manual');
  if (manualCondidition) {
    return 'unbegrenzt'
  }
  const dateCondition = record.locked_until.find(condition => condition.type === 'date');
  const submissionCondition = record.locked_until.find(condition => condition.type === 'submission');

  const result = <>
    {dateCondition ? <Tooltip title={moment.unix(dateCondition.value).format('HH:mm [Uhr], DD.MM.yyyy')}><u>{moment.unix(dateCondition.value).fromNow()}</u></Tooltip> : null}
    {dateCondition && submissionCondition ? <>&nbsp;oder<br/></> : null}
    {submissionCondition ? <><Tooltip title={<>{submissionCondition.value.title}, (möglich von {moment.unix(submissionCondition.value.not_before).format('HH:mm [Uhr], DD.MM.yyyy')} bis {moment.unix(submissionCondition.value.not_after).format('HH:mm [Uhr], DD.MM.yyyy')})</>}><u>POST-Bogen</u></Tooltip>&nbsp;ausgefüllt</> : null}
  </>
  return result;
}

const mapStateToProps = ({ record, user }) => ({
  recordsLoading: record.loading,
  user: user.user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRecords: recordOperations.index,
      destroyRecord: recordOperations.destroy,
      updateRecord: recordOperations.update,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RecordsTable))
