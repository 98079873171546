import types from './types'


export const actions = {
  progressFetchRequest: () => ({
    type: types.PROGRESS_FETCH_REQUEST
  }),

  progressFetchFailure: error => ({
    type: types.PROGRESS_FETCH_FAILURE,
    payload: error
  }),

  progressFetchSuccess: progress => ({
    type: types.PROGRESS_FETCH_SUCCESS,
    payload: progress
  })
}

export default actions