import IconButton from '@material-ui/core/IconButton/IconButton';
import MuiTableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';

const TableBody = ({data, columns, onDetailsAction, onEditAction, onDeleteAction, onLockAction, onUnlockAction}) => {
  if (!data.length) {
    return <MuiTableBody>
      <TableRow>
        <TableCell colSpan={columns.length + 1} align="center">Keine Einträge gefunden...</TableCell>
      </TableRow>
    </MuiTableBody>
  }

  const rowActionsElements = [
    {label: 'Details anzeigen', onClick: row => () => onDetailsAction(row), icon: <VisibilityIcon color="primary"/>},
    {label: 'Bearbeiten', onClick: row => () => onEditAction(row), icon: <EditIcon/>, disabled: row => !row.user_can_update_record},
    {label: 'Löschen', onClick: row => () => onDeleteAction(row), icon: <DeleteIcon/>, disabled: row => !row.user_can_delete_record},
    {label: 'Sperren', onClick: row => () => onLockAction(row), icon: <LockOpenIcon/>, disabled: row => !row.user_can_update_record, hidden: row => row.locked_until && row.locked_until.length},
    {label: 'Entsperren', onClick: row => () => onUnlockAction(row), icon: <LockIcon/>, disabled: row => !row.user_can_update_record, hidden: row => !row.locked_until || !row.locked_until.length},
  ]
    .map(({label, onClick, icon, disabled, hidden}) => row => {
      const isDisabled = disabled && disabled(row)
      const isHidden = hidden && hidden(row)
      if (isHidden) {
        return;
      }
      const iconButton = <IconButton aria-label={label} onClick={onClick(row)} disabled={isDisabled} key={label}>
        {icon}
      </IconButton>
      if (isDisabled) {
        return iconButton
      }
      return <Tooltip title={label} key={label}>
        {iconButton}
      </Tooltip>
    })

  return <MuiTableBody>
    {data.map(row => (
      <TableRow key={row.id}>
        {columns.map(column => (
          <TableCell key={`${row.id}_${column.id}`} component="th" scope="row">
            {(column.render && column.render(row)) || row[column.id]}
          </TableCell>
        ))}
        <TableCell align="right">
          {rowActionsElements.map(rowActionElement => rowActionElement(row))}
        </TableCell>
      </TableRow>
    ))}
  </MuiTableBody>
}

export default React.memo(TableBody, (prevProps, nextProps) => prevProps.data === nextProps.data)