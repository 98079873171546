import Chip from '@material-ui/core/Chip/Chip';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import React from 'react';


const FreetextChip = withStyles(theme => ({
  root: {
    color: '#fff',
    margin: theme.spacing(1 / 5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: '#ccc',
    fontWeight: 600
  },
}))(({classes, message}) => <Tooltip
    title={message}
    placement="bottom">
    <Grid item>
      <Chip size="small" label="🗨" clickable={false} className={classes.root}/>
    </Grid>
  </Tooltip>
)

export default FreetextChip