import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Divider from '@material-ui/core/Divider/Divider';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid/Grid';
import Grow from '@material-ui/core/Grow/Grow';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select/Select';
import Typography from '@material-ui/core/Typography/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { recordOperations } from '../../ducks/records';
import { roomActions, roomOperations } from '../../ducks/rooms';
import { taskOperations } from '../../ducks/tasks';
import { userOperations } from '../../ducks/users';
import TaskSelectDialog from './TaskSelectDialog';

const styles = theme => ({
  background: {
    height: '100%',
    overflow: 'auto',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`
  },
  progress: {
    margin: theme.spacing(2)
  },
  cardContent: {
    maxWidth: 300,
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: 250,
    height: 55,
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  runningItem: {
    borderLeft: '5px solid green',
  }
})

class Room extends React.Component {
  state = {
    error: ''
  }
  handleChangeRoom = event => {
    this.props.selectRoomById(event.target.value.id)
  }

  handleChangeTask = event => {
    const { tasks, selectRoom } = this.props
    selectRoom(null)
    this.handleTaskSelect(tasks.find(task => task.id === event.target.value))
  }

  handleTaskSelect = task => {
    this.props.loadPastRecord({id: -1}) // TODO: this is only called to reset the form. Should be solved another way
    this.props.selectTask(task)
  }

  handleCloseTaskSelectDialog = (task, room) => {
    this.props.selectTask(task)
    if (room) {
      this.props.selectRoom(room)
    }
    if (task) {
      this.props.changeToForms(task)
    }
  }

  onContinuePressed = () => {
    const { task, changeToForms } = this.props
    changeToForms(task)
  }

  componentDidMount() {
    const { rooms, room, tasks, task, selectRoomById, fetchRooms, fetchTasks } = this.props
    if (rooms.length <= 0) fetchRooms()
    if (tasks.length <= 0) fetchTasks()
    if (room && !task) selectRoomById(room.id)
    if (task) this.handleTaskSelect(task)
  }

  renderRoomSelect() {
    const { rooms, room, classes, error, roomLoading } = this.props
    return (
      <FormControl className={classes.formControl} error={!!error}>
        <InputLabel shrink htmlFor="room-label">
          Raum
        </InputLabel>
        <Select
          value={room || ''}
          renderValue={value => value.title}
          inputProps={{
            title: 'room',
            id: 'room-label-placeholder'
          }}
          onChange={this.handleChangeRoom}
          name="Raum"
          className={classes.selectEmpty}
          variant="outlined"
          disabled={roomLoading}
        >
          <MenuItem value="">
            <em>n.A.</em>
          </MenuItem>
          {rooms.map((r, i) => {
            return <MenuItem key={i} value={r}>{r.title}</MenuItem>
          })}
        </Select>
        <FormHelperText>Es werden nur Räume in der Limette aufgelistet.</FormHelperText>
      </FormControl>
    )
  }

  renderTaskSelect() {
    const now = moment()
    const { tasks, task, classes, error } = this.props

    const taskList = tasks.map(task => {
      const currentlyRunning = !!(task.events && task.events.find(event => now.unix() >= event.starts_at - 30*60 && now.unix() <= event.ends_at))
      return <MenuItem className={clsx({[classes.runningItem]: currentlyRunning})} key={task.id} value={task.id}>{task.title}</MenuItem>
    })

    const menuTitles = tasks.reduce((obj, t) => ({...obj, [t.id]: t.title}), {})

    return (
      <FormControl className={classes.formControl} error={!!error}>
        <InputLabel shrink htmlFor="task-label">
          Fall
        </InputLabel>
        <Select
          value={task ? task.id : ''}
          renderValue={value => menuTitles[value]}
          inputProps={{
            title: 'task',
            id: 'task-label-placeholder'
          }}
          onChange={this.handleChangeTask}
          name="Fall"
          className={classes.selectEmpty}
          variant="outlined"
          title={task ? task.title : 'Kein Fall gewählt'}
        >
          <MenuItem value="">
            <em>n.A.</em>
          </MenuItem>
          {taskList}
        </Select>
        <FormHelperText>Es werden nur offene Fälle mit verknüpften EPAs aufgelistet. Laufende Fälle sind grün markiert.</FormHelperText>
      </FormControl>
    )
  }

  render() {
    const { classes, epas, taskOptions, room } = this.props
    const { error } = this.state
    const taskSelectDialogOpen = taskOptions.length > 1
    return (
      <Grid
        className={classes.background}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grow in={true} mountOnEnter unmountOnExit>
          <Card className={classes.card}>
            <div style={{ height: 16 }}/>
            <CardContent className={classes.cardContent}>
              <Typography variant="body2" paragraph>
                Wählen Sie entweder den Raum oder den Fall aus, in dem Sie beobachten wollen:
              </Typography>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {this.renderRoomSelect()}
                <Grid container direction={'row'} alignItems={'center'}>
                  <Grid item xs={5}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={2} align={'center'}>
                    <Typography style={{ fontStyle: 'italic', opacity: 0.5 }} align={'center'}
                                variant={'caption'}>oder</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Divider/>
                  </Grid>
                </Grid>
                {this.renderTaskSelect()}
                <Grow in={!!error} mountOnEnter unmountOnExit>
                  <Grid item>
                    <Typography color={'error'}
                                variant={'caption'}>{error}</Typography>

                  </Grid>
                </Grow>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={epas.length <= 0}
                  className={classes.button}
                  onClick={() => {
                    this.onContinuePressed()
                  }}
                >
                  Weiter
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grow>
        <TaskSelectDialog open={taskSelectDialogOpen} onClose={this.handleCloseTaskSelectDialog} tasks={taskOptions} room={room}/>
      </Grid>
    )
  }
}

const mapStateToProps = ({ room, task, record }) => ({
  rooms: room.rooms,
  room: room.room,
  roomLoading: room.loading,
  roomError: room.error,
  task: task.task,
  tasks: task.tasks,
  taskOptions: task.taskOptions,
  taskLoading: task.loading,
  taskError: task.error,
  epas: record.epas
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signOut: userOperations.signOut,
      fetchRooms: roomOperations.index,
      selectRoomById: roomOperations.selectById,
      selectRoom: roomActions.selectRoom,
      fetchTasks: taskOperations.index,
      selectTask: taskOperations.select,
      changeToForms: task => push(`/tasks/${task.id}/forms`),
      loadPastRecord: recordOperations.loadPastRecord,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Room))
