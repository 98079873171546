import qs from 'qs';

import fetch from '../fetch';

const mongoUrl = process.env.REACT_APP_BACKEND_URL
const index = (user, params) => {
  const query = qs.stringify(params, { encode: false });
  const url = `${mongoUrl}/records?${query}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
    .then(response => ({
      ...response,
      data: response.data.map(record => mapRecordFromApi(record))
    }))
}

const create = (user, recordData) => {
  const url = `${mongoUrl}/records`
  return  fetch(url, {
    method: 'POST',
    body: JSON.stringify(prepareRecordForApi(recordData)),
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
    .then(record => mapRecordFromApi(record))
}

const read = (user, id) => {
  const url = `${mongoUrl}/records/${id}`
  return fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
    .then(record => mapRecordFromApi(record))
}

const update = (user, record) => {
  const url = `${mongoUrl}/records/${record.id}`
  return fetch(url, {
    method: 'PUT',
    body: JSON.stringify(prepareRecordForApi(record)),
    headers: defaultHeaders(user)
  })
    .then(handleResponse)
    .then(record => mapRecordFromApi(record))
}

const destroy = (user, record) => {
  const url = `${mongoUrl}/records/${record.id}`

  return fetch(url, {
    method: 'DELETE',
    headers: defaultHeaders(user)
  })
    .then(response => {
      if (!response.ok) throw new Error(response.statusText)
    })
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  if (!response.ok) throw new Error(response.statusText)
  return response.json()
}

const mapRecordFromApi = record => ({
  ...record, // TODO: check what is needed here
  studentId: record.student_id,
  globalRating: record.global_rating || '',
  createdAt: new Date(record.created_at).toLocaleString(),
  // Normally all records comming from the API should have a non-null observed_at field
  // but old values might not. We dont track if someone explicetly set the observed_at value.
  // TODO: migrate old values in database before changing the following two lines.
  observed_at: record.observed_at || record.created_at,
  observedAt: new Date(record.observed_at || record.created_at).toLocaleString(),
  epas: record.epas.map(({id, supervision_level, competencies, selection_log}) => ({
    id,
    supervision: supervision_level,
    competencies: competencies.map(({competency_id, value}) => ({
      competencyId: competency_id,
      value,
    })),
    selectionLog: selection_log.map(({competency_id, value, timestamp}) => ({
      competencyId: competency_id,
      value,
      timestamp,
    })),
  })),
});

const prepareRecordForApi = record => ({
  ...record, // TODO: check what is needed here
  student_id: record.studentId,
  global_rating: record.globalRating === '' ? null : record.globalRating,
  epas: record.epas.map(({id, supervision, competencies, selectionLog}) => ({
    id,
    supervision_level: supervision,
    competencies: (competencies || []).map(({competencyId, value}) => ({
      competency_id: competencyId,
      value,
    })),
    selection_log: (selectionLog || []).map(({competencyId, value, timestamp}) => ({
      competency_id: competencyId,
      value,
      timestamp,
    })),
  }))
})

export default { index, create, read, update, destroy, mapRecordFromApi }