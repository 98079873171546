import Slider from '@material-ui/core/Slider/Slider';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';


const styles = theme => ({
  sliderLabel: {
    fontWeight: 'bold',
  }
})

const marks = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
]

const MiniCexScale = props => {
  const { classes, value, onChange, disabled } = props

  const comp = props => value > 0 ? <span {...props}/> : <span {...props} style={{opacity: 0}}/>

  return (
    <Slider
      value={value}
      step={1}
      min={1}
      max={9}
      color={'secondary'}
      ThumbComponent={comp}
      marks={marks}
      onChange={onChange}
      disabled={disabled}
      classes={{
        markLabel: classes.sliderLabel
      }}
    />
  )
}

export default withStyles(styles)(MiniCexScale)
