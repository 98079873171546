import actions from './actions'


const medforgeApiUrl = `${process.env.REACT_APP_MEDFORGE_URL}/api`

export const index = (queryParams = {}) => (dispatch, getState) => {
  const {query: name, eventIds, more} = queryParams;
  dispatch(actions.studentListRequest(queryParams))
  const user = getState().user.user
  const roomStudent = getState().room.currentStudent
  let url = `${medforgeApiUrl}/users`
  const params = [];
  if (name) {
    params.push(`name=${name}`)
  }
  if (eventIds) {
    params.push(`events=${eventIds.join(',')}`)
  }
  if (more) {
    params.push(`more=1`)
  }
  if (roomStudent) {
    params.push(`add_by_id=${roomStudent.studentId}`)
  }
  url += params.length ? `?${params.join('&')}` : ''
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  }).then(response => {
    return response.json()
  }).then(students => {
    students = students.map(({id, login, firstname, lastname, semester, attendant, photo}) => ({
      id, login, firstName: firstname, lastName: lastname, semester, attendant, photo
    }))
    dispatch(actions.studentListSuccess(students, queryParams))
    return students
  }).catch(error => {
    dispatch(actions.studentListFailure(error))
  })
}

export default { index }
