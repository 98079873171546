const BASE_PATH = 'api/records/'

const types = {
  RECORD_GET_REQUEST: BASE_PATH + 'RECORD_GET_REQUEST',
  RECORD_GET_FAILURE: BASE_PATH + 'RECORD_GET_FAILURE',
  RECORD_GET_SUCCESS: BASE_PATH + 'RECORD_GET_SUCCESS',
  RECORD_LIST_REQUEST: BASE_PATH + 'RECORD_LIST_REQUEST',
  RECORD_LIST_FAILURE: BASE_PATH + 'RECORD_LIST_FAILURE',
  RECORD_LIST_SUCCESS: BASE_PATH + 'RECORD_LIST_SUCCESS',
  RECORD_HISTORY_LIST_REQUEST: BASE_PATH + 'RECORD_HISTORY_LIST_REQUEST',
  RECORD_HISTORY_LIST_FAILURE: BASE_PATH + 'RECORD_HISTORY_LIST_FAILURE',
  RECORD_HISTORY_LIST_SUCCESS: BASE_PATH + 'RECORD_HISTORY_LIST_SUCCESS',
  RECORD_STORE_REQUEST: BASE_PATH + 'RECORD_STORE_REQUEST',
  RECORD_STORE_SUCCESS: BASE_PATH + 'RECORD_STORE_SUCCESS',
  RECORD_STORE_FAILURE: BASE_PATH + 'RECORD_STORE_FAILURE',
  RECORD_UPDATE_REQUEST: BASE_PATH + 'RECORD_UPDATE_REQUEST',
  RECORD_UPDATE_SUCCESS: BASE_PATH + 'RECORD_UPDATE_SUCCESS',
  RECORD_UPDATE_FAILURE: BASE_PATH + 'RECORD_UPDATE_FAILURE',
  RECORD_DELETE_REQUEST: BASE_PATH + 'RECORD_DELETE_REQUEST',
  RECORD_DELETE_SUCCESS: BASE_PATH + 'RECORD_DELETE_SUCCESS',
  RECORD_DELETE_FAILURE: BASE_PATH + 'RECORD_DELETE_FAILURE',
  SET_VALIDATION_ERRORS_VISIBILITY: BASE_PATH + 'SET_VALIDATION_ERRORS_VISIBILITY',
  SET_ROLE: BASE_PATH + 'SET_ROLE',
  SET_STUDENT: BASE_PATH + 'SET_STUDENT',
  SET_SUPERVISION_LEVEL: BASE_PATH + 'SET_SUPERVISION_LEVEL',
  SET_GLOBAL_RATING: BASE_PATH + 'SET_GLOBAL_RATING',
  SET_OBSERVED_AT: BASE_PATH + 'SET_OBSERVED_AT',
  SET_TASK_EPAS: BASE_PATH + 'SET_TASK_EPAS',
  SELECT_EPA: BASE_PATH + 'SELECT_EPA',
  SELECT_BEHAVIOUR: BASE_PATH + 'SELECT_BEHAVIOUR',
}

export default types