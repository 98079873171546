import { colors, Typography, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  paper: {
    border: '2px solid',
    borderColor: colors.red[900],
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.red[900],
  },
})

const ValidationErrorDialog = props => {
  const { open, validationErrors, onCancel, onSubmit, classes } = props

  const formattedValidationErrors = Object.values(validationErrors
    .sort((a, b) => a.itemTypeId - b.itemTypeId)
    .reduce((obj, error) => {
      obj[error.itemTypeId] = obj[error.itemTypeId] || []
      obj[error.itemTypeId].push(error)
      return obj
    }, {})
  ).map(errorGroup => {
    const itemTypeId = errorGroup[0].itemTypeId
    let title = ''
    let messageParts = []
    if (itemTypeId < 14) {
      title = `EPA ${itemTypeId}`
      if (errorGroup.find(error => error.property === 'supervision')) {
        messageParts.push('Supervisionslevel')
      }
    } else if (itemTypeId === 14) {
      title = 'Soziale Kompetenzen'
      const missingCompetencies = errorGroup.filter(error => error.property === 'competency' && error.error === 'missing-value')
      if (missingCompetencies.length < errorGroup.length) {
        console.warn('Found errors that are not handled in validation error dialog')
      }
      if (missingCompetencies.length) {
        messageParts.push(missingCompetencies.length !== 1 ? `${missingCompetencies.length} Einschätzungen` : `${missingCompetencies.length} Einschätzung`)
      }
    } else if (itemTypeId === 15) {
      title = 'Mini-CEX'
      if (errorGroup.find(error => error.property === 'role')) {
        messageParts.push('Rollenzuordnung')
      }
      const missingCompetencies = errorGroup.filter(error => error.property === 'competency' && error.error === 'missing-value')
      if (missingCompetencies.length) {
        messageParts.push(missingCompetencies.length !== 1 ? `${missingCompetencies.length} Einschätzungen` : `${missingCompetencies.length} Einschätzung`)
      }
    }
    return `${title}: ${messageParts.join(', ')}`
  })

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle disableTypography id="alert-dialog-slide-title">
        <Typography variant="h6" className={classes.title}>
          <span>Fehlende Werte</span>
          <span>⚠</span>
        </Typography>
      </DialogTitle>
      <DialogContent dividers id="alert-dialog-slide-description">
        <Typography variant="body1">
          Bei der Beobachtung fehlen noch folgende Werte:
        </Typography>
        <ul>
          {formattedValidationErrors.map((error, index) => <li key={index}>{error}</li>)}
        </ul>
        <Typography variant="body1">
          Soll die Beobachtung trotzdem gespeichert werden?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="primary">
          Nein, ich ergänze die fehlenden Werte
        </Button>
        <Button variant="outlined" onClick={onSubmit} color="primary">
          Ja, trotzdem speichern und fehlende Werte ignorieren
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(ValidationErrorDialog)