import ClientOAuth2 from 'client-oauth2';

import config from '../../config/config';
import fetch from '../fetch';
import actions from './actions';


const oauthRedirectUrl = process.env.REACT_APP_OAUTH_REDIRECT_URL
const oauthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID
const medforgeUrl = process.env.REACT_APP_MEDFORGE_URL
const medforgeApiUrl = `${process.env.REACT_APP_MEDFORGE_URL}/api`
const mongoUrl = process.env.REACT_APP_BACKEND_URL

const medforgeAuth = oauthRedirectUrl => {
  return new ClientOAuth2({
    clientId: oauthClientId,
    // TODO: we should use Auth Flow with PKCE when implemented in Passport
//   clientSecret: 'secret',
//   accessTokenUri: `${medforgeUrl}/oauth/token`,
    authorizationUri: `${medforgeUrl}/#!/oauth?client_name=myEPAs`,
    redirectUri: oauthRedirectUrl,
    scopes: []
  })
}

/**
 * Redirect to OAuth authorization URI
 */
const authenticateWithMedforge = () => dispatch => {
// TODO: we should use Auth Flow with PKCE when implemented in Passport
//  window.location = medforgeAuth.code.getUri()
  window.location = medforgeAuth(oauthRedirectUrl).token.getUri()
}

/**
 * Handles the redirect URI with an authorization_code and requests
 * an access_token.
 * @param {string} url OAuth redirect URI with code param
 */
const authenticateWithUrl = (url, authFlow) => dispatch => {

  if (!['token', 'code'].includes(authFlow)) {
    console.warn('Only code and token are valid OAuth flows')
    return
  }
  let accessToken
  dispatch(actions.signInRequest())
  const tokenFlow = authFlow === 'token' ? medforgeAuth(oauthRedirectUrl).token : medforgeAuth.code
  tokenFlow.getToken(url)
    .then(token => {
      accessToken = token.accessToken
      return fetch(`${medforgeUrl}/api/user`, { headers: { authorization: 'Bearer ' + token.accessToken } })
    })
    .then(response => response.json())
    .then(response => {
      const user = { ...response, accessToken: accessToken }
      dispatch(actions.signInSuccess(user))
    })
    .catch(err => dispatch(actions.signInFailure(err)))
}

/**
 * Request sign out from current device. Given token will be revoked.
 */
const signOut = () => (dispatch, getState) => {
  localStorage.removeItem(`${config.localStoragePrefix}user`)
  const user = getState().user.user
  const promise = user && user.accessToken ?
    fetch(`${mongoUrl}/logout`, {
      method: 'POST',
      headers: { authorization: 'Bearer ' + user.accessToken }
    })
    .then(_ => fetch(`${medforgeApiUrl}/logout`, {
      method: 'POST',
      headers: { authorization: 'Bearer ' + user.accessToken }
    })) :
    Promise.resolve()
  promise.finally(_ => dispatch(actions.reset()))
}

const addMinimalPermission = () => (dispatch, getState) => {
  const user = getState().user.user
  const url = `${medforgeApiUrl}/user/add-minimal-mypeas-permission`
  dispatch(actions.miminalPermissionRequest())
  fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => response.json())
    .then(_ => {
      dispatch(actions.miminalPermissionSuccess())
    })
    .catch(err => dispatch(actions.miminalPermissionFailure(err)))
}

export default {
  signOut,
  addMinimalPermission,
  authenticateWithMedforge,
  authenticateWithUrl
}