import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  withStyles
} from '@material-ui/core';
import { Assignment, CloseTwoTone, Description, Movie, Person, PlayArrow } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

const StyledListItem = withStyles({
  secondaryAction: {
    paddingRight: '190px',
  }
})(ListItem)

const CustomListItem = ({item, onClick, onTaskAssignmentClick, onDelete, perspective}) => {
  perspective = perspective || 'recipient'
  const {
    createdAt,
    validUntil,
    assignedBy: { name: creator },
    assignedTo: { name: recipient },
    task: { title: taskTitle, pdfUrl, assignment: taskAssignment },
    student: {
      firstName: studentFirstName,
      lastName: studentLastName,
    },
    videoUrl,
    performedAt,
  } = item

  let itemTitle = <>Fall: <b>{taskTitle}</b>, Student:in <b>{studentFirstName} {studentLastName}</b></>
  if (performedAt) {
    itemTitle = <>{itemTitle}, {moment(performedAt).format('DD.MM.YYYY, HH:mm')} Uhr</>
  }
  const deleteBtnTitle = perspective === 'creator' ? 'Auftrag löschen' : 'Auftrag ablehnen'
  const itemSubtitleCreationDate = <span title={`${moment(createdAt).format('HH:mm')} Uhr`}>{moment(createdAt).format('DD.MM.YYYY')}</span>
  const itemSubtitleUntilDate = <b>{moment(validUntil).format('DD.MM.YYYY, HH:mm')} Uhr</b>
  const itemSubtitle = perspective === 'creator' ? <>Auftrag an <b>{recipient}</b> vom {itemSubtitleCreationDate}. Abzuschließen bis {itemSubtitleUntilDate}.</> :
    <span>Beauftragt von {creator} am {itemSubtitleCreationDate}. Bitte bis {itemSubtitleUntilDate} abschließen.</span>
  return <StyledListItem>
    <ListItemAvatar>
      <Avatar>
        <Person/>
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={itemTitle}
      secondary={itemSubtitle}
    />
    <ListItemSecondaryAction>
      <IconButton disabled={!taskAssignment} edge="end" onClick={event => onTaskAssignmentClick(event, item)}>
        <Tooltip title="Aufgabenstellung des Falls anzeigen">
          <Assignment/>
        </Tooltip>
      </IconButton>
      <IconButton disabled={!pdfUrl} edge="end" href={pdfUrl} target='_blank'>
        <Tooltip title={`Dokumentation von ${studentFirstName} ${studentLastName} aufrufen`}>
          <Description/>
        </Tooltip>
      </IconButton>
      <IconButton disabled={!videoUrl} edge="end" href={videoUrl} target='_blank'>
        <Tooltip title={`Aufzeichnung von ${studentFirstName} ${studentLastName} aufrufen (Login erforderlich)`}>
          <Movie/>
        </Tooltip>
      </IconButton>
      { onClick ? <IconButton edge="end" onClick={event => onClick(event, item)}>
        <Tooltip title="Beobachtung starten">
          <PlayArrow/>
        </Tooltip>
      </IconButton> : null }
      <IconButton edge="end" onClick={event => onDelete(event, item, perspective)}>
        <Tooltip title={deleteBtnTitle || 'Auftrag ablehnen'}>
          <CloseTwoTone/>
        </Tooltip>
      </IconButton>
    </ListItemSecondaryAction>
  </StyledListItem>
}

export default CustomListItem