import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = theme => ({})

const Column = props => {
  const { columns } = props
  return (
    <Grid item xs={12}>
      <Grid container direction={'row'}>
        {columns.map(column => (
          <Grid key={column.id + column.title} item xs={column.width}>
            <Grid container direction={'column'} alignItems={'center'}>
              <Typography align={'center'} style={{
                whiteSpace: 'pre-line'
              }}>{column.title}</Typography>
              {column.additional_text ? <Typography
                variant={'caption'}
                align={'center'}>({column.additional_text})</Typography> : null}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Column)
