import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow/Grow';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import IfasLogo from '../../assets/images/ifas_logo.png';
import FakultaetLogo from '../../assets/images/mfm_logo.png';
import config from '../../config/config';
import { userOperations } from '../../ducks/users';

const { authenticateWithMedforge: authenticate } = userOperations

const styles = theme => ({
  isStaging: {
    '&::before': {
      content: '"Achtung: Testsystem"',
      color: '#fff',
      background: 'red',
      display: 'block',
      textAlign: 'center',
    },
    border: '4px solid red',
  },
  background: {
    height: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`,
    position: 'absolute',
    left: '0px',
    width: '100%',
    overflow: 'hidden'
  },
  progress: {
    margin: theme.spacing(2)
  },
  cardContent: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    width: 250,
    height: 55,
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    margin: theme.spacing(1),
    boxShadow: 'none'
  }
})

class LoginForm extends React.Component {
  onLoginPressed = () => {
    this.props.authenticate()
  }

  componentDidMount() {
  }

  getStartPathForUser(user) {
    const canCreateRecord = user && user.permissions && !!user.permissions.filter(userPermission => config.requiredPermissions.room.includes(userPermission)).length
    if (canCreateRecord) {
      return '/rooms'
    }
    const canShowRecords = user && user.permissions && !!user.permissions.filter(userPermission => config.requiredPermissions.record.includes(userPermission)).length
    if (canShowRecords) {
      return '/records'
    }
    const canAccessProgressControl = user && user.permissions && !!user.permissions.filter(userPermission => config.requiredPermissions.progressControl.includes(userPermission)).length
    if (canAccessProgressControl) {
      return '/progress-control'
    }
    return '/disclaimer'
  }

  render() {
    const { user, loading, classes } = this.props

    if (loading) return <LinearProgress color={'secondary'}/>
    if (user) return <Redirect to={this.getStartPathForUser(user)}/>

    return (
      <Grid
        className={classes.background}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grow in={true} mountOnEnter unmountOnExit>

          <Card className={`${classes.card} ${(config.env === 'staging' ? classes.isStaging : null)}`}>
            <div style={{ height: 16 }}/>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h4" component="h1">
                  myEPAs
                </Typography>
                <Typography variant="subtitle2" paragraph>
                  <Link component={RouterLink} to="/about"  title="Mehr Informationen über myEPAs anzeigen">
                    EPAs bewerten und einsehen
                  </Link>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  title="Login via MedForGe"
                  onClick={() => {
                    this.onLoginPressed()
                  }}
                >
                  Anmelden
                </Button>
                <Grid
                  container
                  direction="row"
                  justifyContent={'space-evenly'}
                  alignContent={'center'}
                  className={classes.logos}
                >
                  <Grid item>
                    <img
                      style={{ margin: 8 }}
                      width={115}
                      src={FakultaetLogo}
                      alt={''}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      style={{ margin: 8 }}
                      width={115}
                      src={IfasLogo}
                      alt={''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grow>
      </Grid>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  loading: user.loading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authenticate
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginForm))
