exports.sortByFn = query => (a, b) => {
  if (!query) {
    return
  }
  const ascendingOrder = !query.startsWith('-')
  const queryWithoutOrder = ascendingOrder ? query : query.substring(1)
  const paths = queryWithoutOrder.split(',')

  let first = a
  let second = b
  if (!ascendingOrder) {
    first = b
    second = a
  }
  const getDeep = (obj, path) => path.split('.').reduce((val, part) => part ? val && val[part] : val, obj)

  while (paths.length) {
    const path = paths.shift()
    const firstValue = getDeep(first, path)
    const secondValue = getDeep(second, path)
    const comparisonResult = typeof firstValue === 'string' ? firstValue.localeCompare(secondValue, 'de', {sensitivity: 'base', numeric: 'true'}) : firstValue - secondValue
    if (comparisonResult !== 0) {
      return comparisonResult
    }
  }
}
