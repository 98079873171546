import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IfasLogo from '../../assets/images/ifas_logo.png';
import FakultaetLogo from '../../assets/images/mfm_logo.png';
import config from '../../config/config';
import { userOperations } from '../../ducks/users';

const styles = theme => ({
  background: {
    minHeight: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`
  },
  margin: {
    margin: theme.spacing(1)
  },
  card: {
    maxWidth: '1000px',
    margin: '20px',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    },
  },
  cardContent: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  logos: {
    borderTop: '1px solid #ccc',
    marginTop: '20px',
    paddingTop: '20px',
  },
  logo: {
    maxWidth: '250px',
    width: '35%',
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  lightButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.primary.light
  }
})

function About(props) {
  const { classes, signOut, user, history } = props
  const { requiredPermissions } = config

  const isLoggedIn = !!user;
  const canAccessProgressControl = user && user.permissions && !!user.permissions.filter(userPermission => requiredPermissions.progressControl.includes(userPermission)).length
  const canAccessRooms = user && user.permissions && !!user.permissions.filter(userPermission => requiredPermissions.room.includes(userPermission)).length

  function handleSignOut() {
    signOut()
    redirectToLogin()
  }

  function redirectToLogin() {
    history.push('/login')
  }

  function redirectToProgressControl() {
    history.push('/progress-control')
  }

  function redirectToRoom() {
    history.push('/rooms')
  }

  function redirectToDisclaimer() {
    history.push('/disclaimer')
  }

  return <Grid
    className={classes.background}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column">
          <Typography variant="h3" component="h1" gutterBottom>Über "myEPAs"</Typography>
          <Typography variant="body1" paragraph>
            Mit "myEPAs" (EPA:  Entrustable Professional Activity) können Beobachter:innen die Performance von Studierenden anhand der 13 Core-EPAs bewerten (Fremdeinschätzung).
            Weitere Infos sind auf der Webseite des <a href='https://medicampus.uni-muenster.de/ccel/projects/my-epas/' target='_blank' rel='noopener noreferrer'>Kompetenzzentrums E-Learning</a> zu finden.
          </Typography>
          <Typography variant="body1" paragraph>
            Aktuell gibt es <strong>zwei Module</strong> für deren Verwendung bestimmte Berechtigungen notwendig sind. Diese Berechtigungen können nur von Mitarbeiter:innen des IfAS vergeben werden. Bitte wenden Sie sich im Zweifel an Friedrich Pawelka (pawelka@uni-muenster.de).
          </Typography>
          <Typography variant="h5" component="h2" id="bewertungsmodul" gutterBottom>1) Bewertungsmodul</Typography>
          <Typography variant="body1" paragraph>
            Das <i>Bewertungsmodul</i> ist für Beobachter:innen gedacht. Dort können Beobachtungen bzw. Fremdeinschätzungen zu den EPAs dokumentiert werden. Um auf das <i>Bewertungsmodul</i> zugreifen zu können, benötigen Sie <strong>mindestens die Berechtigung "myEPAs: Beobachten"</strong> die Ihnen von Mitarbeiter:innen des IfAS gegeben werden.
          </Typography>
          {isLoggedIn ?
            canAccessRooms ?
              <Button variant="contained" onClick={redirectToRoom} className={clsx(classes.margin)}>Zum Bewertungsmodul</Button> :
              <Typography variant="body1" color="error">Sie sind eingeloggt, jedoch fehlt Ihnen das Recht <i>myEPAs: Beobachten</i>. Bitte wenden Sie sich an eine/n Mitarbeiter:in des IfAS, wenn Sie auf das Bewertungsmodul zugreifen möchten.</Typography>
            : ''
          }
          <hr/>
          <Typography variant="h5" component="h2" id="dashboardmodul" gutterBottom>2) Dashboardmodul</Typography>
          <Typography variant="body1" paragraph>
            Im <i>Dashboardmodul</i> werden alle Fremdeinschätzungen nach EPA gruppiert grafisch dargestellt. Um auf das <i>Dashboardmodul</i> zugreifen zu können, benötigen Sie <strong>mindestens die Berechtigung "myEPAs: Eigene Beobachtungen einsehen"</strong> die Ihnen von Mitarbeiter:innen des IfAS gegeben werden.
          </Typography>
          <ul>
            <li><strong>Studierende</strong> sehen alle Fremdeinschätzungen, die für sie abgegeben wurden. Darüber hinaus besteht demnächst die Möglichkeit, Selbsteinschätzungen, die über LimeSurvey erhoben wurden, zu überführen und ebenfalls anzeigen zu lassen.</li>
            <li><strong>Beobachter:innen</strong> sehen alle Fremdeinschätzungen, die sie dokumentiert haben.</li>
            <li><strong>Tutor:innen</strong> können zum Zwecke der Beratung alle Fremdeinschätzungen einsehen. Dieser Zugriff wird demnächst dokumentiert und jede:r Student:in kann dann auf Anfrage Einsicht in seine persönliche Zugriffsliste bekommen.</li>
          </ul>
          {isLoggedIn ?
            canAccessProgressControl ?
              <Button variant="contained" onClick={redirectToProgressControl} className={clsx(classes.margin)}>Zum Dashboardmodul</Button> :
              <Typography variant="body1">Sie sind eingeloggt, jedoch haben Sie noch keine Berechtigung, um auf das Dashboardmodul zuzugreifen. <Button variant="contained" onClick={redirectToDisclaimer} className={clsx(classes.margin)}>Berechtigung beantragen</Button></Typography>
            : ''
          }
          {!isLoggedIn ?
            <>
              <Typography variant="h5" component="h2" id="hinweise_login" gutterBottom>Hinweise zum Login</Typography>
              <Typography variant="body1" paragraph>
                Beim Login werden Sie zu MedForGe umgeleitet. Dort loggen Sie sich mit Ihrer Medicampus-Kennung ein.
                <br/>Hier geht es zum Login für myEPAs:
              </Typography>
              <Button variant="outlined" color="primary" onClick={redirectToLogin}>Zum Login</Button>
            </>
            : ''
          }
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={'space-between'}
          className={classes.logos}
        >
          <a
            href="https://www.medizin.uni-muenster.de/"
            target="_blank"
            rel="noopener noreferrer"
            title="Website der medizinischen Fakultät Münster"
            className={classes.logo}
          >
            <img
              src={FakultaetLogo}
              alt={'Logo der Medizinischen Fakultät der Universität Münster'}
              style={{maxWidth: '100%'}}
            />
          </a>
          <a
            href="https://medicampus.uni-muenster.de/ifas.html"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.logo}
            title="Website des IfAS"
          >
            <img
              src={IfasLogo}
              alt={'Logo des Instituts für Ausbildung und Studienangelegenheiten'}
              style={{maxWidth: '100%'}}
            />
          </a>
        </Grid>
      </CardContent>
    </Card>
    {user ?
      <Button onClick={handleSignOut} className={clsx(classes.margin, classes.lightButton)}>Abmelden</Button> :
      <Button onClick={redirectToLogin} className={clsx(classes.margin, classes.lightButton)}>Zum Login</Button>
    }
  </Grid>
}

const mapStateToProps = ({user}) => ({
  user: user.user,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    signOut: userOperations.signOut,
  },
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(About))
