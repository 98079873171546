import fetch from '../fetch';
import { recordActions, recordTypes } from '../records';
import { taskOperations } from '../tasks';
import actions from './actions';

const medforgeApiUrl = `${process.env.REACT_APP_MEDFORGE_URL}/api`

export const index = () => (dispatch, getState) => {
  dispatch(actions.roomListRequest())
  const url = `${medforgeApiUrl}/rooms`
  const user = getState().user.user
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => response.json())
    .then(rooms => {
      // Filter those without linked EPAs
      dispatch(actions.roomListSuccess(rooms))
      dispatch(getMachine())
      return rooms
    })
    .catch(error => {
      dispatch(
        actions.roomListFailure(error)
      )
    })
}

export const getMachine = () => (dispatch, getState) => {
  dispatch(actions.machineRequest())
  const url = `${medforgeApiUrl}/machine`
  const user = getState().user.user
  return fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => response.status === 200 ? response.json() : null)
    .then(machine => {
      dispatch(actions.machineSuccess(machine))
      const room = getState().room.room
      const rooms = getState().room.rooms
      if (!room && machine && rooms.length) {
        const autoSelectedRoom = rooms.find(r => r.title === machine.room)
        if (autoSelectedRoom) {
          dispatch(selectById(autoSelectedRoom.id))
        }
      }
      return machine
    })
    .catch(error => {
      dispatch(
        actions.machineFailure(error)
      )
    })
}

export const selectById = roomId => (dispatch, getState) => {
  if (!roomId || roomId === '') {
    return;
  }
  dispatch(actions.roomGetRequest())
  dispatch({ type: recordTypes.RECORD_HISTORY_LIST_REQUEST })
  dispatch(recordActions.setTaskEpas([]))
  const url = `${medforgeApiUrl}/rooms/${roomId}`
  const user = getState().user.user
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => response.json())
    .then(room => {
      if (room.tasks && room.tasks.length > 1) {
        dispatch(taskOperations.selectMultiple(room.tasks))
      } else {
        dispatch(taskOperations.select(room.tasks[0] || null))
      }
      dispatch(actions.roomGetSuccess(room))
      return room
    })
    .catch(error => {
      dispatch(
        actions.roomGetFailure(error)
      )
    })
}

export const reset = () => dispatch => {
  dispatch(actions.reset())
}

export default { index, selectById, reset, getMachine }
