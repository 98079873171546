import { combineReducers } from 'redux';

import observationAssignment from './observation-assignments';
import progress from './progress';
import record from './records';
import room from './rooms';
import student from './students';
import task from './tasks';
import user, { userTypes } from './users';


const appReducer = combineReducers({
  room,
  user,
  progress,
  student,
  record,
  task,
  observationAssignment
})

const rootReducer = (state, action) => {
  if (action.type === userTypes.RESET_AUTHENTICATION) state = undefined
  return appReducer(state, action)
}

export default rootReducer
