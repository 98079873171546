import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';
import TaskIcon from '@material-ui/icons/Assignment';
import RoomIcon from '@material-ui/icons/Room';
import ScheduleIcon from '@material-ui/icons/Schedule';
import RatingIcon from '@material-ui/icons/StarHalf';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1.5)
  },
  icon: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark
  }
}))

const Case = props => {
  const classes = useStyles()
  return (
    <Grid item xs={2}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title={'Fall'}
                 placement="bottom">
          <TaskIcon fontSize={'small'} className={classes.icon}/>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Room = props => {
  const classes = useStyles()
  return (
    <Grid item xs={1}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title={'Raum/Station'}
                 placement="bottom">
          <RoomIcon fontSize={'small'} className={classes.icon}/>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Student = props => {
  const classes = useStyles()
  return (
    <Grid item xs={2}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title={'Student:in'}
                 placement="bottom">
          <SvgIcon fontSize={'small'} className={classes.icon}>
            <path
              d="M12,3L1,9L12,15L21,10.09V17H23V9M5,13.18V17.18L12,21L19,17.18V13.18L12,17L5,13.18Z"/>
          </SvgIcon>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Observer = props => {
  const classes = useStyles()
  return (
    <Grid item xs={2}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title={'Beobachter:in'}
                 placement="bottom">
          <SvgIcon fontSize={'small'} className={classes.icon}>
            <path
              d="M12,9.5A2.5,2.5 0 0,0 9.5,12A2.5,2.5 0 0,0 12,14.5A2.5,2.5 0 0,0 14.5,12A2.5,2.5 0 0,0 12,9.5M12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13M12,9.5A2.5,2.5 0 0,0 9.5,12A2.5,2.5 0 0,0 12,14.5A2.5,2.5 0 0,0 14.5,12A2.5,2.5 0 0,0 12,9.5M12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,16C9.37,16 7,14.43 6,12C7.38,8.69 11.19,7.12 14.5,8.5C16.08,9.16 17.34,10.42 18,12C17,14.43 14.63,16 12,16M12,9.5A2.5,2.5 0 0,0 9.5,12A2.5,2.5 0 0,0 12,14.5A2.5,2.5 0 0,0 14.5,12A2.5,2.5 0 0,0 12,9.5M12,13A1,1 0 0,1 11,12A1,1 0 0,1 12,11A1,1 0 0,1 13,12A1,1 0 0,1 12,13Z"/>
          </SvgIcon>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Date = props => {
  const classes = useStyles()
  return (
    <Grid item xs={3}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title="Beobachtungszeitpunkt"
                 placement="bottom">
          <ScheduleIcon fontSize={'small'} className={classes.icon}/>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.observedAt}</Typography>
          { props.observedAt !== props.createdAt ?
            <Typography variant={'caption'}>(Erstellt: {props.createdAt})</Typography>
          : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

const Rating = props => {
  const classes = useStyles()
  return (
    <Grid item xs={2}>
      <Grid container direction={'row'} alignItems={'center'}>
        <Tooltip title={'Globales Rating'}
                 placement="bottom">
          <RatingIcon fontSize={'small'} className={classes.icon}/>
        </Tooltip>
        <Grid item>
          <Typography variant={'body2'}>{props.value ? props.value : '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Metadata = props => {
  const classes = useStyles()

  return (
    <Grid className={classes.root} container>
      <Date observedAt={props.observedAt} createdAt={props.createdAt}/>
      <Case value={props.case}/>
      <Student value={props.student}/>
      <Observer value={props.observer}/>
      <Rating value={props.rating}/>
      <Room value={props.room}/>
    </Grid>
  )
}

export default Metadata