import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { recordActions } from '../../ducks/records';

const styles = theme => ({
  card: {
    padding: '10px',
    marginBottom: '20px'
  }
})

function RoleSelection(props) {
  const { role, setRole } = props
  const [value, setValue] = useState(role)

  useEffect(() => setValue(role), [role])

  const handleChange = (event) => {
    const role = event.target.value
    setRole(role)
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Ihre Rolle</FormLabel>
      <RadioGroup aria-label="role" name="role" value={value} onChange={handleChange} row>
        <FormControlLabel value="physician" control={<Radio />} label="Mediziner:in" />
        <FormControlLabel value="psychologist" control={<Radio />} label="Psycholog:in" />
      </RadioGroup>
    </FormControl>
  )

}

const mapStateToProps = ({ record }) => ({
  role: record.record.role,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setRole: recordActions.setRole
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RoleSelection))