import { colors, IconButton, Paper, Tooltip } from '@material-ui/core';
import color from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import InfoDialog from '../../commons/InfoDialog';
import { recordActions } from '../../ducks/records';
import { selectVisibleValidationErrors } from '../../ducks/records/reducers';
import MiniCexScale from './MiniCexScale';

const styles = theme => ({
  competencyBlock: {
    padding: '15px 15px 0',
    borderRadius: '4px',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginBottom: '10px',
    },
    '&:nth-child(even)': {
      backgroundColor: color[100],
    },
    '&:nth-child(odd)': {
      backgroundColor: color[50],
    }
  },
  competencyTitle: {
    paddingRight: '20px',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sliderPaper: {
    padding: '5px 95px',
    paddingTop: '20px',
    position: 'relative',
  },
  boundary: {
    borderRight: '1px solid #7b7b7b',
  },
  resetButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  description: {
    padding: '10px 0',
    '& ul': {
      margin: '0'
    }
  },
  item: {
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    '&:not(:last-child)': {
      borderRight: '1px solid #7b7b7b',
    },
  },
  hasErrors: {
    border: '2px solid',
    borderColor: colors.red[900],
  },
})

const MiniCexCompetency = props => {
  const { classes, competency, select, disabled, recordValidationErrors } = props
  const storedValue = competency.value === undefined ? null : competency.value
  const hasError = recordValidationErrors.find(error => error.property === 'competency' && error.propertyId === competency.id)
  return (
    <Grid container item className={classes.competencyBlock}>
      <Grid item xs={3} className={classes.competencyTitle}>
        <Typography color="primary" variant="h6"><strong>{competency.id - 1}. {competency.title}</strong></Typography>
        {competency.help ? <InfoDialog title={<>Erklärung zum Item <b>{ competency.title }</b></>} content={competency.help} tooltip="Erklärung zum Item"/> : null}
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={12}>
          <Paper className={clsx(classes.sliderPaper, hasError && classes.hasErrors)} elevation={disabled ? 0 : 1}>
            <IconButton className={classes.resetButton} color="primary" disabled={disabled || storedValue < 1} onClick={() => select({
              competencyId: competency.id,
              value: null
            })}>
              <Tooltip title="Skala zurücksetzen">
                <SettingsBackupRestoreIcon/>
              </Tooltip>
            </IconButton>
            {competency.scale === 'mini-cex' ? <MiniCexScale disabled={disabled} value={storedValue} onChange={(event, value) => select({
              competencyId: competency.id,
              value
            })}/> : null}
          </Paper>
        </Grid>
        <Grid container item xs={12}>
          <Grid item className={classes.item} xs={4}><Typography>nicht zufriedenstellend</Typography></Grid>
          <Grid item className={classes.item} xs={4}><Typography>zufriedenstellend</Typography></Grid>
          <Grid item className={classes.item} xs={4}><Typography>exzellent</Typography></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ record }) => ({
  recordValidationErrors: selectVisibleValidationErrors(record)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      select: recordActions.selectBehaviour
    },
    dispatch
  )


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MiniCexCompetency))
