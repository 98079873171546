import Slider from '@material-ui/core/Slider/Slider';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Grid } from '@material-ui/core';


const styles = theme => ({
  sliderLabel: {
    fontWeight: 'bold',
  },
  sliderPoles: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    lineHeight: 1,
    marginTop: -5,
    marginBottom: 5,
    textAlign: 'center',
  },
  leftPole: {
    transform: 'translateX(-50%)'
  },
  rightPole: {
    transform: 'translateX(50%)'
  }
})

const marks = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
]

const ManifestationScale = props => {
  const { classes, value, onChange, disabled } = props

  const comp = props => value !== null ? <span {...props}/> : <span {...props} style={{opacity: 0}}/>

  return (
    <>
    <Slider
      value={value}
      step={1}
      min={1}
      max={6}
      color={'secondary'}
      ThumbComponent={comp}
      marks={marks}
      onChange={onChange}
      disabled={disabled}
      classes={{
        markLabel: classes.sliderLabel
      }}
    />
    <Grid container justifyContent="space-between" className={classes.sliderPoles}>
      <Grid item className={classes.leftPole}>Niedrige Kompetenz-<br/>Ausprägung</Grid>
      <Grid item className={classes.rightPole}>Hohe Kompetenz-<br/>Ausprägung</Grid>
    </Grid>
    </>
  )
}

export default withStyles(styles)(ManifestationScale)
