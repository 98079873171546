import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import epasJson from '../../assets/epas';
import RecordDialog from '../../commons/record-dialog/RecordDialog';
import config from '../../config/config';
import { progressOperations } from '../../ducks/progress';
import { studentOperations } from '../../ducks/students';
import { history } from '../../redux';
import ChartCard from './ChartCard';
import ComparisonChart from './ComparisonChart';
import CustomMultiSelection from './CustomMultiSelection';
import CustomScatterChart from './CustomScatterChart';
import CustomSelect from './CustomSelect';
import EpaScatterChart from './EpaScatterChart';
import OverviewCard from './OverviewCard';

class ProgressControl extends Component {
  widgets = [
    {group: 'overview', name: 'overview', title: 'Übersicht'},
    {group: 'overview', name: 'legend', title: 'Legende Supervisionslevel'},
    {group: 'overview', name: 'comparison', title: 'Kohortenvergleich'},
    {group: 'epas', name: 'epa-1', title: 'EPA 1'},
    {group: 'epas', name: 'epa-2', title: 'EPA 2'},
    {group: 'epas', name: 'epa-3', title: 'EPA 3'},
    {group: 'epas', name: 'epa-4', title: 'EPA 4'},
    {group: 'epas', name: 'epa-5', title: 'EPA 5'},
    {group: 'epas', name: 'epa-6', title: 'EPA 6'},
    {group: 'epas', name: 'epa-7', title: 'EPA 7'},
    {group: 'epas', name: 'epa-8', title: 'EPA 8'},
    {group: 'epas', name: 'epa-9', title: 'EPA 9'},
    {group: 'epas', name: 'epa-10', title: 'EPA 10'},
    {group: 'epas', name: 'epa-11', title: 'EPA 11'},
    {group: 'epas', name: 'epa-12', title: 'EPA 12'},
    {group: 'epas', name: 'epa-13', title: 'EPA 13'},
    {group: 'social-competencies', name: 'social-competencies-1', title: 'Agency'},
    {group: 'social-competencies', name: 'social-competencies-2', title: 'Kommunion'},
    {group: 'social-competencies', name: 'social-competencies-3', title: 'Interpersonelle Resilienz'},
  ]

  state = {
    selectedRecord: null,
    selectedWidgets: this.widgets
      .filter(widget => widget.default !== false)
      .map(widget => widget.name),
    availableTasks: [],
    availableWidgets: [],
    selectedTasks: [],
  }

  componentDidMount() {
    const { match, indexStudents, user, location } = this.props
    const { studentId } = match.params
    const { requiredPermissions } = config
    const params = new URLSearchParams(location.search)
    const selectedTaskIds = params.get('tasks')
    this.setState({ selectedTasks: (selectedTaskIds && selectedTaskIds.split(',')) || []}, () => {
      const canSeeStudent = !!studentId && user.permissions.filter(userPermission => requiredPermissions.progressControlShowStudentsList.includes(userPermission)).length > 0;
      indexStudents();
      if (!canSeeStudent) {
        this.onChangeStudent(user)
      } else {
        this.loadProgress(studentId)
      }
    })
  }

  loadProgress(userId) {
    const { selectedTasks } = this.state
    const { indexProgress } = this.props
    const progressPromise = selectedTasks ?
      indexProgress(userId, selectedTasks) :
      indexProgress(userId)
    progressPromise.then(_ => {
      const { progress } = this.props
      // TODO: show all tasks for people who can change students
      const availableTasks = progress.cases.map(
        ({id, title}) => ({name: id, title})
      )
      const availableWidgets = this.getAvailableWidgetsFromProgress(progress)
      this.setState({availableTasks, availableWidgets})
    })
  }

  getAvailableWidgetsFromProgress = progress => {
    return this.widgets.filter(widget => {
      switch (widget.group) {
        case 'epas':
          const epaId = +widget.name.replace('epa-', '')
          return progress.scatter && !!Object.values(progress.scatter).length && progress.scatter[epaId]
        case 'social-competencies':
          const competencyId = +widget.name.replace('social-competencies-', '')
          return progress.competenciesScatter && !!Object.values(progress.competenciesScatter).length && progress.competenciesScatter[competencyId]
        case 'overview':
          return progress.scatter && !!Object.values(progress.scatter).length
        default:
          return true
      }
    })
  }

  handleCloseRecordDialog = () => {
    this.setState({ selectedRecord: null })
  }

  handleOpenRecordDialog = (recordId, epaNumber) => {
    const { progress } = this.props
    const record = progress.records.find(r => r.id === recordId)
    const recordWithEpa = {
      ...record,
      // TODO: do not filter other epas out but open the selected epa by default (and maybe highlight it)
      epas: record.epas
        .filter(epa => epa.id === epaNumber)
    }
    this.setState({ selectedRecord: recordWithEpa })
  }

  onChangeStudent = student => {
    const { selectedTasks } = this.state
    const search = selectedTasks.length ? `?tasks=${selectedTasks.join(',')}` : ''
    history.push(`/progress-control/${student.id}${search}`)
    this.loadProgress(student.id)
  }

  onCloseGraph = itemId => {
    this.setSelectedWidgets(this.state.selectedWidgets.filter(id => id !== itemId))
  }

  setSelectedWidgets = selectedWidgets => {
    this.setState({ selectedWidgets })
  }

  setSelectedTasks = selectedTasks => {
    const { match } = this.props
    this.setState({ selectedTasks }, () => {
      const search = selectedTasks.length ? `?tasks=${selectedTasks.join(',')}` : ''
      history.push(`/progress-control/${match.params.studentId}${search}`)
      this.loadProgress(match.params.studentId)
    })
  }

  formatNoOptionsMessage = ({inputValue}) => {
    if (inputValue.length > 1) {
      return `Kein Ergebnis für "${inputValue}"`
    }
    return 'Mindestens zwei Buchstaben eingeben, um Suche zu starten.';
  }

  formatLoadingMessage = () => {
    return 'Suche Studierende...';
  }

  handleInputChange = input => {
    const { indexStudents, studentsQuery } = this.props
    const query = {...studentsQuery, query: input, more: true}
    if (input.length > 1) {
      return indexStudents(query)
    }
    if (input.length === 0 && input !== studentsQuery.query) {
      return indexStudents(query)
    }
  }

  render() {
    const { classes, progress, loading, user, students, match } = this.props
    const { selectedRecord, selectedWidgets, availableTasks, availableWidgets, selectedTasks} = this.state

    const canSelectStudents = !user.permissions.includes('myepas-read-own-records') ||
      user.permissions.filter(permission => permission.startsWith('myepas')).length > 1;

    const selectedStudent = students.find(u => u.value.id === +match.params.studentId);

    if (!progress) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <CustomSelect
              label="Student:in"
              options={students}
              selected={selectedStudent}
              setSelected={this.onChangeStudent}
              disabled={!canSelectStudents}
              loading={loading}
              onInputChange={this.handleInputChange}
              noOptionsMessage={this.formatNoOptionsMessage}
              loadingMessage={this.formatLoadingMessage}
            />
            <CustomMultiSelection
              options={availableTasks}
              selected={selectedTasks}
              setSelected={this.setSelectedTasks}
              renderValue="#count Fälle"
              renderValueSingular="#count Fall"
              label="Nach Fällen filtern"
            />
          </Grid>
          <Grid item>
            <CustomMultiSelection
              options={availableWidgets}
              selected={selectedWidgets}
              setSelected={this.setSelectedWidgets}
              renderValue="#count Blöcke gewählt"
              renderValueSingular="#count Block gewählt"
              label="Ansicht"
            />
          </Grid>
        </Grid>
        <div className={classes.grid}>
        { loading ?
          <>
            <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
            <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
            <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
            <Skeleton variant="rect" className={classes.skeleton}></Skeleton>
          </>:
          availableWidgets.length ?
          <>
            {selectedWidgets.includes('overview') && !!availableWidgets.find(widget => widget.name === 'overview') &&
              <ChartCard
                graphId="overview"
                title="Überblick"
                onClose={this.onCloseGraph}
              >
                <OverviewCard items={progress.radar} activeItems={selectedWidgets}/>
              </ChartCard>
            }
            {selectedWidgets.includes('legend') && !!availableWidgets.find(widget => widget.name === 'legend') &&
              <ChartCard
                graphId="legend"
                title="Legende Supervisionslevel"
                avatar={<InfoIcon/>}
                onClose={this.onCloseGraph}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Grid item sm={6}>
                    <ol start={0} className={classes.ol}>
                      <li>Ich würde die/den Student:in bei dieser Tätigkeit <b>nicht zuschauen</b> lassen</li>
                      <li>Ich würde die/den Student:in diese Tätigkeit <b>nur beobachten</b> lassen</li>
                      <li>Ich würde die/den Student:in diese Tätigkeit mit <b>vollständiger direkter Supervision</b> bzw. der <b>Möglichkeit zum direkten Eingreifen</b> übernehmen lassen</li>
                    </ol>
                  </Grid>

                  <Grid item sm={6}>
                    <ol start={3} className={classes.ol}>
                      <li>Ich würde die/den Student:in diese Tätigkeit unter <b>räumlich entfernter Supervision mit direkter Erreichbarkeit und Kontrolle des Ergebnisses</b> ausführen lassen</li>
                      <li>Ich würde die/den Student:in diese Tätigkeit unter <b>räumlich entfernter Supervision ohne direkte Erreichbarkeit</b> ausführen lassen</li>
                      <li>Ich würde der/dem Student:in zutrauen, diese Tätigkeit unter <b>voller Verantwortung ohne Supervision durchzuführen und andere in dieser Tätigkeit anzuführen und zu supervidieren</b></li>
                    </ol>
                  </Grid>
                </Grid>
              </ChartCard>
            }
            {selectedWidgets.includes('comparison') && !!availableWidgets.find(widget => widget.name === 'comparison') &&
              <ChartCard
              graphId="comparison"
                title="Kohortenvergleich"
                onClose={this.onCloseGraph}
              >
                <ComparisonChart items={progress.radar} activeItems={selectedWidgets}/>
              </ChartCard>
            }
            {Object.entries(progress.scatter)
              .filter(scatterData => scatterData[1].length >= 1)
              .filter(scatterData => selectedWidgets.includes(`epa-${scatterData[0]}`))
              .map(scatterData => {
                const epaNumber = +scatterData[0];
                const epaObject = epasJson.find(ej => ej.id === epaNumber);
                const epaDescription = epaObject && epaObject.title
                const title = <><strong>EPA {epaNumber}</strong>: {epaDescription}</>
                const data = scatterData[1]
                return (
                  <ChartCard key={epaNumber} graphId={`epa-${epaNumber}`} title={title} avatar={epaNumber} numberOfItems={data.length} onClose={this.onCloseGraph}>
                    <EpaScatterChart items={data} handleClick={item => this.handleOpenRecordDialog(item.record, item.epa)}/>
                  </ChartCard>
                )
            })}
            {Object.entries(progress.competenciesScatter)
              .filter(scatterData => selectedWidgets.includes(`social-competencies-${scatterData[0]}`))
              .map(scatterData => {
              const socialCompetencies = epasJson.find(ej => ej.id === 14);
              const competencyId = +scatterData[0];
              const competencyConfig = socialCompetencies.competencies.find(c => c.id === competencyId)
              const title = <>Soziale Kompetenzen: <strong>{competencyConfig.title}</strong></>
              const data = scatterData[1]
              return (
                <ChartCard key={scatterData[0]} graphId={`social-competencies-${competencyId}`} title={title} avatarColor={competencyConfig.color} avatar={competencyConfig.short_title_graph || competencyConfig.title.charAt(0)} numberOfItems={data.length} onClose={this.onCloseGraph}>
                  <CustomScatterChart items={data} handleClick={item => this.handleOpenRecordDialog(item.record, item.epa)}/>
                </ChartCard>
              )
            })}
          </> :
          <Grid container justifyContent='center'>
            <Grid item>
              Keine Daten vorhanden...
            </Grid>
          </Grid>
        }
        </div>
        <RecordDialog record={selectedRecord} onClose={this.handleCloseRecordDialog} TransitionComponent={Transition}/>
      </div>
    )
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const mapStateToProps = ({ user, progress, student }) => ({
  user: user.user,
  students: student.students.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
  studentsQuery: student.queryParams,
  progress: progress.progress,
  loading: progress.loading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      indexProgress: progressOperations.index,
      indexStudents: studentOperations.index,
    },
    dispatch
  )

const styles = theme => ({
  root: {
    backgroundColor: '#f9f9f9',
    overflow: 'auto',
    flex: '1'
    // height: '100%',
    // marginBottom: 100,
    // padding: theme.spacing(0.5)
  },
  grid: {
    [theme.breakpoints.up(1100)]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: 250
  },
  selectEmpty: {
    backgroundColor: 'white',
    marginTop: theme.spacing(2)
  },
  appBar: {
    boxShadow: 'none'
  },
  loading: {
    margin: 'auto'
  },
  skeleton: {
    margin: '9px',
    height: '350px',
  },
  inputSelect: {
    minHeight: '56px'
  },
  ol: {
    margin: 0,
    paddingInlineStart: '2rem',
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProgressControl))
