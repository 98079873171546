import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';

import { selectSelectedCompetencies } from '../../ducks/records/reducers';
import SaveButton from './SaveButton';
import SocialCompetency from './SocialCompetency';

const styles = theme => ({
  bar: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
})

class SocialCompetenciesForm extends React.Component {
  render() {
    const { classes, form, selectedCompetencies, task } = this.props
    return (
      form && task ?
        <Grid container className={classes.bar}>
          <Grid container item xs={12}>
            <Grid container direction="column">
              {form.competencies.map(competency => {
                const selectedCompetency = selectedCompetencies.find(c => c.competencyId === competency.id) || {}
                return <SocialCompetency key={competency.id} competency={{...competency, value: selectedCompetency.value || null}}/>
              })}
            </Grid>
          </Grid>
          <SaveButton/>
        </Grid> : null
    )
  }
}

const mapStateToProps = ({ task, record }) => ({
  form: record.selectedEpa,
  selectedCompetencies: selectSelectedCompetencies(record),
  task: task.task,
})

export default connect(
  mapStateToProps
)(withStyles(styles)(SocialCompetenciesForm))
