import types from './types';

export const actions = {
  setTaskEpas: epas => ({
    type: types.SET_TASK_EPAS,
    payload: epas,
  }),
  selectEpa: index => ({
    type: types.SELECT_EPA,
    payload: index,
  }),

  recordGetRequest: () => ({
    type: types.RECORD_GET_REQUEST
  }),
  recordGetFailure: error => ({
    type: types.RECORD_GET_FAILURE,
    payload: error
  }),
  recordGetSuccess: record => ({
    type: types.RECORD_GET_SUCCESS,
    payload: record
  }),

  recordListRequest: () => ({
    type: types.RECORD_LIST_REQUEST
  }),
  recordListFailure: error => ({
    type: types.RECORD_LIST_FAILURE,
    payload: error
  }),
  recordListSuccess: () => ({
    type: types.RECORD_LIST_SUCCESS,
  }),

  recordHistoryListRequest: () => ({
    type: types.RECORD_HISTORY_LIST_REQUEST
  }),
  recordHistoryListFailure: error => ({
    type: types.RECORD_HISTORY_LIST_FAILURE,
    payload: error
  }),
  recordHistoryListSuccess: list => ({
    type: types.RECORD_HISTORY_LIST_SUCCESS,
    payload: list
  }),

  recordStoreRequest: () => ({
    type: types.RECORD_STORE_REQUEST
  }),
  recordStoreFailure: error => ({
    type: types.RECORD_STORE_FAILURE,
    payload: error
  }),
  recordStoreSuccess: record => ({
    type: types.RECORD_STORE_SUCCESS,
    payload: record
  }),

  recordUpdateRequest: () => ({
    type: types.RECORD_UPDATE_REQUEST
  }),
  recordUpdateFailure: error => ({
    type: types.RECORD_UPDATE_FAILURE,
    payload: error
  }),
  recordUpdateSuccess: record => ({
    type: types.RECORD_UPDATE_SUCCESS,
    payload: record
  }),

  recordDeleteRequest: () => ({
    type: types.RECORD_DELETE_REQUEST
  }),
  recordDeleteSuccess: () => ({
    type: types.RECORD_DELETE_SUCCESS
  }),
  recordDeleteFailure: error => ({
    type: types.RECORD_DELETE_FAILURE,
    payload: error
  }),

  setRole: role => ({
    type: types.SET_ROLE,
    payload: role,
  }),
  setStudent: student => ({
    type: types.SET_STUDENT,
    payload: student,
  }),
  setSupervisionLevel: supervision => ({
    type: types.SET_SUPERVISION_LEVEL,
    payload: supervision,
  }),
  setGlobalRating: globalRating => ({
    type: types.SET_GLOBAL_RATING,
    payload: globalRating,
  }),
  setObservationTime: observedAt => ({
    type: types.SET_OBSERVED_AT,
    payload: observedAt,
  }),
  selectBehaviour: behaviour => ({
    type: types.SELECT_BEHAVIOUR,
    payload: behaviour,
  }),
  showValidationErrors: () => ({
    type: types.SET_VALIDATION_ERRORS_VISIBILITY,
    payload: true,
  }),
}

export default actions