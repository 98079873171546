import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import ReactSelect from 'react-select';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 250
  },
})

const CustomSelect = props => {
  const { classes, options, loading, selected, setSelected, disabled, label, ...rest } = props
  const onChange = option => setSelected(option.value)
  return (
    <FormControl className={classes.formControl}>
      <Typography variant="caption">{ label }</Typography>
      <ReactSelect
        options={options}
        menuPortalTarget={document.querySelector('body')}
        isLoading={loading}
        onChange={onChange}
        value={selected}
        isDisabled={disabled}
        {...rest}
      />
    </FormControl>
  )
}

export default withStyles(styles)(CustomSelect)