const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const oneDayInMilliseconds = 86400000;

const config = {
  localStoragePrefix: `myepas.${env}.`,
  reduxKey: `myepas:${env}`,
  env,
  requiredPermissions: {
    room: ['myepas-create-record', 'myepas-update-records'],
    form: ['myepas-create-record', 'myepas-update-records'],
    record: ['myepas-create-record', 'myepas-read-records', 'myepas-update-records'],
    createRecord: ['myepas-create-record','myepas-update-records'],
    updateRecord: ['myepas-update-records'],
    deleteRecord: ['myepas-update-records'],
    progressControl: ['myepas-read-own-records', 'myepas-create-record', 'myepas-read-records', 'myepas-update-records'],
    progressControlShowStudentsList: ['myepas-create-record', 'myepas-read-records', 'myepas-update-records'],
  },
  updateAccessDurationForObservers: 2 * oneDayInMilliseconds,
}

export default config