import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import RecordTabs from './RecordList';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawerGrid: {
    maxWidth: '6%',
    flexBasis: '6%'
  },
  mainGrid: {
    height: '100%',
    maxWidth: '94%',
    flexBasis: '94%'
  },
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    width: '100%',
    display: 'flex',
    overflowX: 'hidden',
    maxWidth: '6%',
    flexBasis: '6%',
    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: '#efefef',
    boxShadow: 'unset'
  },
  drawerHeader: {
    minHeight: theme.spacing(8),
    boxShadow: '8px rgba(0,0,0,0.7)'
  },
  contentShift: {
    flexGrow: 1,
    marginLeft: 0
  },
}))

export default function PersistentDrawerLeft(props) {
  const classes = useStyles()

  return (
    <Grid container justifyContent={'space-between'} direction={'row'} style={{ height: '100%', overflow: 'auto' }}>
      <Grid item xs={1} className={classes.drawerGrid}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={true}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}/>
          <RecordTabs onSelect={props.onSelectRecord}/>
        </Drawer>
      </Grid>
      <Grid item xs={11} className={classes.mainGrid}>
        {props.children}
      </Grid>
    </Grid>
  )
}
