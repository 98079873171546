import config from '../../config/config';
import { studentActions, studentOperations } from '../students';
import actions from './actions';
import recordsApi from './api';

export const index = query => (dispatch, getState) => {
  const recordState = getState().record
  const user = getState().user.user
  const params = mapStateToSearchParams({...recordState, ...query})

  dispatch(actions.recordListRequest())
  return recordsApi.index(user, params)
    .then(result => {
      dispatch(actions.recordListSuccess())
      return result;
    })
    .catch(error => {
      dispatch(actions.recordListFailure(error))
    })
}

export const indexHistory = task => (dispatch, getState) => {
  const user = getState().user.user
  const params = {
    filter: {
      observer_id: user.id,
      case_id: task.id,
      created_at: {
        gte: Date.now() - config.updateAccessDurationForObservers
      }
    },
    sort: '-created_at'
  }

  dispatch(actions.recordHistoryListRequest())
  return recordsApi.index(user, params)
    .then(result => {
      dispatch(actions.recordHistoryListSuccess(result.data))
      return result.data
    })
    .catch(error => {
      dispatch(actions.recordHistoryListFailure(error))
    })
}

export const store = record => (dispatch, getState) => {
  const user = getState().user.user
  dispatch(actions.recordStoreRequest())
  return recordsApi.create(user, record)
    .then(record => {
      dispatch(actions.recordStoreSuccess(record))
      dispatch(indexHistory({ id: record.case.id }))
      return record
    }).catch(error => {
      dispatch(actions.recordStoreFailure(error))
  })
}
// TODO: remove id param
export const update = (id, record) => (dispatch, getState) => {
  const user = getState().user.user

  dispatch(actions.recordUpdateRequest())
  return recordsApi.update(user, record)
    .then(record => {
      dispatch(actions.recordUpdateSuccess(record))
      dispatch(indexHistory({ id: record.case.id }))
      return record
    }).catch(error => {
      dispatch(actions.recordUpdateFailure(error))
    })
}

export const destroy = record => (dispatch, getState) => {
  const user = getState().user.user

  dispatch(actions.recordDeleteRequest())
  return recordsApi.destroy(user, record)
    .then(_ => {
      dispatch(actions.recordDeleteSuccess())
    })
    .catch(error => {
      dispatch(actions.recordDeleteFailure(error))
    })
}

export const selectById = id => (dispatch, getState) => {
  if (!id) {
    dispatch(initializeStudentsForTask(getState().task.task))
    return dispatch(loadPastRecord({id: -1}))
  }
  const user = getState().user.user
  const pastRecords = getState().record.pastRecords
  const cachedRecord = pastRecords.find(record => record.id === id)
  if (cachedRecord) {
    dispatch(initializeStudentsForRecord(cachedRecord))
    return dispatch(loadPastRecord(cachedRecord))
  }

  dispatch(actions.recordGetRequest())
  return recordsApi.read(user, id)
    .then(record => {
      dispatch(initializeStudentsForRecord(record))
      return dispatch(loadPastRecord(record))
    })
    .catch(error => {
      dispatch(actions.recordGetFailure(error))
    })
}

export const loadPastRecord = record => dispatch => {
  if (record.id === -1) {
    dispatch(actions.recordGetSuccess())
  }
  else {
    dispatch(actions.recordGetSuccess(record))
    return record
  }
}

const initializeStudentsForTask = task => {
  const currentEvents = task.events && task.events.filter(event => !!event && event.starts_at * 1000 < Date.now() && event.ends_at * 1000 > Date.now());
  if (currentEvents.length) {
    return studentOperations.index({eventIds: currentEvents.map(event => event.id)})
  } else {
    return studentActions.studentListSuccess([])
  }
}

const initializeStudentsForRecord = record => {
  if (record.case.eventId) {
    return studentOperations.index({eventIds: [record.case.eventId]})
  } else {
    return studentActions.studentListSuccess([])
  }
}

const validFilter = {
  caseId: 'case_id',
  studentId: 'student_id',
}
const mapStateToSearchParams = state => {
  let params = { }
  if (state.orderBy) {
    params.sort = state.orderBy;
    if (state.orderDirection === 'desc') {
      params.sort = `-${params.sort}`
    }
  }
  if (state.pageNumber !== undefined) {
    params.page = {
      number: state.pageNumber
    }
  }
  if (state.pageSize) {
    params.page = params.page || {}
    params.page.size = state.pageSize
  }
  if (state.query) {
    params.query = state.query
  }
  if (state.filter) {
    params.filter = Object.entries(state.filter)
      .filter(([key]) => !!validFilter[key])
      .reduce((obj, [key, value]) => ({...obj, [validFilter[key]]: value}), {})
  }
  return params;
}

export default { index, store, selectById, update, destroy, indexHistory, loadPastRecord }
