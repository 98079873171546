import { colors } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { amber, green } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SaveIcon from '@material-ui/icons/Save';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { recordActions, recordOperations } from '../../ducks/records';
import ValidationErrorDialog from './ValidationErrorDialog';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: 'dark-grey'
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

function MySnackbarContentWrapper(props) {
  const classes = useStyles1()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon}/>
        </IconButton>
      ]}
      {...other}
    />
  )
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
}

const SaveButtonTitle = ({record}) => {
  const studentName = record.student && <b>{record.student.firstName} {record.student.lastName}</b>
  if (record.id !== -1) {
    return studentName ? <>Bestehende Beobachtung für&nbsp;{studentName}&nbsp;überschreiben</> :
    <>Bestehende Beobachtung überschreiben</>
  }
  return studentName ?
    <>Neue Beobachtung für&nbsp;{studentName}&nbsp;speichern</> :
    <>Neue Beobachtung speichern</>
}

const styles = theme => ({
  bar: {
    height: 55,
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    boxShadow: 'none',
  },
  hasErrors: {
    border: '2px solid',
    borderColor: colors.red[900],
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  }
})

class SaveButton extends React.Component {

  state = {
    open: false,
    validationErrorDialog: false,
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ open: false })
  }

  handleClick = () => {
    const { observer, room, task, store, update, currentRecord, validationErrors, validationErrorsVisible, showValidationErrors, caseEpas } = this.props
    let objToStore = {
      ...currentRecord,
      case_id: task ? task.id : 0,
      observer_id: observer.id,
      room_id: room ? room.id : null,
      epas: caseEpas.map(caseEpa => currentRecord.epas.find(epa => epa.id === caseEpa.id) || ({id: caseEpa.id})),
    }
    if (currentRecord.id !== -1) {
      this.setState({ open: true })
      update(currentRecord.id, objToStore);
    } else {
      if (validationErrors.length && !validationErrorsVisible) {
        this.setState({ validationErrorDialog: true});
        showValidationErrors()
      } else {
        this.setState({ open: true })
        const currentEvent = task.events && task.events.find(event => !!event && event.starts_at * 1000 < Date.now() && event.ends_at * 1000 > Date.now());
        if (currentEvent) {
          objToStore.event_id = currentEvent.id;
        }
        store(objToStore)
      }
    }
  }

  handleValidationErrorDialogCancel = event => {
    this.setState({ validationErrorDialog: false })
  }

  handleValidationErrorDialogSubmit = event => {
    this.setState({ validationErrorDialog: false })
    this.handleClick()
  }

  render() {
    const { classes, uploading, snack, currentRecord, success, validationErrors, validationErrorsVisible } = this.props
    const { open, validationErrorDialog } = this.state

    const hasValidationErrors = validationErrorsVisible && validationErrors.length

    return (
      <Grid item xs={12} style={{ width: '100%' }}>
        <Grid container direction={'row'} justifyContent={'flex-end'}>
          <Button onClick={this.handleClick} color="primary" size={'medium'} variant="contained"
                  className={clsx(hasValidationErrors && classes.hasErrors, classes.bar)} disabled={uploading || !currentRecord.studentId}>
            <SaveIcon fontSize={'small'} className={classes.leftIcon}/>
            <SaveButtonTitle record={currentRecord}/>
          </Button>
          <ValidationErrorDialog
            validationErrors={validationErrors}
            open={validationErrorDialog}
            onCancel={this.handleValidationErrorDialogCancel}
            onSubmit={this.handleValidationErrorDialogSubmit}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={open}
            autoHideDuration={8000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id'
            }}
          >
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant={success ? 'success' : success == null ? 'info' : 'error'}
              message={snack}
            />
          </Snackbar>
        </Grid>
        <Grid style={{ height: 40 }}/>
      </Grid>
    )
  }
}

const mapStateToProps = ({ user, room, record, task }) => ({
  observer: user.user,
  task: task.task,
  room: room.room,
  success: record.success,
  uploading: record.uploading,
  snack: record.snackMessage,
  currentRecord: record.record,
  history: record.pastRecords,
  validationErrors: record.validationErrors,
  validationErrorsVisible: record.validationErrorsVisible,
  caseEpas: record.epas,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      store: recordOperations.store,
      update: recordOperations.update,
      showValidationErrors: recordActions.showValidationErrors,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SaveButton))