import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  withStyles
} from '@material-ui/core';
import React from 'react';

const SortBySelect = withStyles({
  formControl: {
    minWidth: '253px'
  }
})(({sortBy, onChange, perspective, classes}) => {
  perspective = perspective || 'recipient'
  return <FormControl className={classes.formControl}>
    <InputLabel>Sortieren nach</InputLabel>
    <Select value={sortBy} onChange={onChange}>
      <MenuItem value={'validUntil'}>Abzuschließen bis (aufsteigend)</MenuItem>
      <MenuItem value={'-validUntil'}>Abzuschließen bis (absteigend)</MenuItem>
      <MenuItem value={'task.title'}>Fall</MenuItem>
      <MenuItem value={'student.lastName,student.firstName'}>Student:in</MenuItem>
      { perspective === 'creator' ? <MenuItem value={'assignedTo.name'}>Beauftragte Person</MenuItem> : null }
      { perspective === 'recipient' ? <MenuItem value={'assignedBy.name'}>Auftrag von</MenuItem> : null }
    </Select>
  </FormControl>
})

export default SortBySelect