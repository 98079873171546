import { makeStyles, withStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider/Slider';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

const marks = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
]

const labels = [
  'nicht zuschauen',
  'nur beobachten',
  'direkte Supervision',
  'entfernte Supervision, direkt erreichbar',
  'entfernte Supervision',
  'ohne Supervision'
]

const StyledSlider = withStyles({
  rail: {
    height: 5
  },
  mark: {
    height: 5
  },
  track: {
    height: 5
  },
  thumb: {
    marginTop: -4
  },
  markLabel: {
    fontWeight: 'bold',
    fontSize: '1rem'
  }
})(Slider)

const SupervisionScale = props => {
  const { value, onChange, disabled } = props

  const comp = props => value !== null ? <span {...props}/> : <span {...props} style={{opacity: 0}}/>

  return (
    <StyledSlider
      disabled={disabled}
      value={value}
      step={1}
      min={0}
      max={5}
      color={'secondary'}
      ThumbComponent={comp}
      marks={marks}
      onChange={onChange}
      valueLabelDisplay='auto'
      ValueLabelComponent={labelComponent}
      valueLabelFormat={num => labels[num]}
    />
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    width: 'max-content',
    fontSize: '.9rem',
    fontWeight: 'normal',
    transform: 'translateY(-2em)',
    borderRadius: '3px',
    padding: '2px 4px',
    backgroundColor: '#fff2e8',
    display: 'none',
    boxShadow: '0px 0px 2px 1px #ccc'
  },
  visible: {
    display: 'block'
  }
}))

const labelComponent = forwardRef((props, ref) => {
  const classes = useStyles()
  const { children, open, value } = props
  return (
    <children.type ref={ref} {...children.props}>
      <div className={clsx(classes.wrapper, {[classes.visible]: open})}>{value}</div>
    </children.type>
  )
})

export default SupervisionScale