import Avatar from '@material-ui/core/Avatar/Avatar';
import Grow from '@material-ui/core/Grow/Grow';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224
  },
  tabs: {
    borderRight: `unset`
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  },
  avatarSelected: {
    border: `${theme.spacing(1 / 4)}px solid ${theme.palette.secondary.light}`
  },
  indicator: {
    backgroundColor: 'transparent'
  }
}))

const AntTab = withStyles(theme => ({
  root: {
    minWidth: theme.spacing(10.75),
    opacity: 1 / 3,
    '&:hover': {
      color: theme.palette.secondary.light,
      backgroundColor: '',
      opacity: 1
    },
    '&$selected': {
      color: theme.palette.secondary.main
    },
    '&:focus': {
      color: theme.palette.secondary.main
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />)

const RecordTabs = props => {
  const classes = useStyles()
  const { pastRecords, currentRecord, currentStudent, onSelect } = props

  const recordsToRender = [{id: -1}].concat(pastRecords)
  if (currentRecord && currentRecord.id !== -1 && !pastRecords.find(record => record.id === currentRecord.id)) {
    recordsToRender.push(currentRecord)
  }
  const idToFind = currentRecord ? currentRecord.id : -1
  const index = recordsToRender.findIndex(record => record.id === idToFind);

  const renderRecordCircle = (record, currentIndex) => <AntTab disableRipple key={record.id} icon={
    <Tooltip
      title={record.id !== -1 ? `Beobachtung von ${(record.student && record.student.firstName) || '?'} ${(record.student && record.student.lastName) || '?'} (${record.observedAt})` : currentStudent && index === 0 ? `Nicht gespeicherte Beobachtung von ${currentStudent.label}` : 'Neue Beobachtung erstellen'}
      placement="right">
        <Avatar className={clsx(classes.avatar, { [classes.avatarSelected]: index === currentIndex })}>
          {
            record.id !== -1 ?
            <Typography
            variant={'caption'}>{
                [(record.student && record.student.firstName) || '?', (record.student && record.student.lastName) || '?']
                .map(word => word.charAt(0))
                .join('')
            }</Typography> :
            <Typography
              variant={'caption'}>Neu</Typography>
          }
        </Avatar>
    </Tooltip>} {...a11yProps(record.id)} />

  const handleChange = (event, newIndex) => {
    if (index === newIndex) return
    const record = recordsToRender[newIndex];
    onSelect(record)
  }

  return (
    <Grow in={true} mountOnEnter unmountOnExit>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={index}
        classes={{ indicator: classes.indicator }}
        onChange={handleChange}
        aria-label="vertical_epa_tabs"
      >
        {recordsToRender.map((record, index) => renderRecordCircle(record, index))}
      </Tabs>
    </Grow>
  )
}

const mapStateToProps = ({ record, task, student }) => ({
  currentTask: task.task,
  pastRecords: record.pastRecords,
  currentRecord: record.record,
  currentStudent: student.student
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordTabs)