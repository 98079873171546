import types from './types'


export const actions = {
  reset: () => ({
    type: types.RESET_AUTHENTICATION
  }),

  resetError: () => ({
    type: types.RESET_ERROR
  }),

  signInRequest: () => ({
    type: types.SIGN_IN_REQUEST
  }),

  signInFailure: error => ({
    type: types.SIGN_IN_FAILURE,
    payload: error
  }),

  signInSuccess: user => ({
    type: types.SIGN_IN_SUCCESS,
    payload: user
  }),

  signOutRequest: () => ({
    type: types.SIGN_OUT_REQUEST
  }),

  signOutFailure: error => ({
    type: types.SIGN_OUT_FAILURE,
    payload: error
  }),

  signOutSuccess: () => ({
    type: types.SIGN_OUT_SUCCESS
  }),

  miminalPermissionRequest: () => ({
    type: types.MINIMAL_PERMISSION_REQUEST
  }),

  miminalPermissionFailure: error => ({
    type: types.MINIMAL_PERMISSION_FAILURE,
    payload: error
  }),

  miminalPermissionSuccess: () => ({
    type: types.MINIMAL_PERMISSION_SUCCESS
  })
}

export default actions