import Card from '@material-ui/core/Card/Card';
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import Grow from '@material-ui/core/Grow/Grow';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { recordActions } from '../../ducks/records';
import { selectSelectedCompetencies } from '../../ducks/records/reducers';


const styles = theme => ({
  cardContainer: {
    padding: `0px 0px ${theme.spacing(0.75)}px ${theme.spacing(0.75)}px`,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1 / 6)
    }
  },
  card: {
    height: '100%',
    boxShadow: 'unset',
    borderRadius: '4px',
    lineHeight: 0,
    border: '1px solid rgba(0,0,0,0)',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'unset',
      borderRadius: '0px'
    }
  },
  cardSelected: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  cardContent: {
    padding: theme.spacing(1.4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.75)
    }
  },
  cardActionArea: {
    height: '100%'
  }
})

const Behaviour = props => {
  const theme = useTheme()
  const { column, classes, competency, select, behaviour, selectedCompetencies } = props
  const isSelected = !!selectedCompetencies.find(competency =>
      competency.value === behaviour.value &&
      competency.competencyId === behaviour.competency_id
    )
  return (
    <Grid item xs={column.width}
          className={classes.cardContainer}>
      <Grow in={true} mountOnEnter unmountOnExit>
        <Card
          className={clsx(classes.card, { [classes.cardSelected]: isSelected })}
          style={{
            color: isSelected ? '#e3e3e3' : 'black',
            backgroundColor: isSelected ?
              theme.palette.primary.main : competency.color
          }}>

          <CardActionArea
            className={classes.cardActionArea}
            onClick={() => select({
              competencyId: behaviour.competency_id,
              value: isSelected ? null : behaviour.value
            })}>
            <CardContent className={classes.cardContent}>
              <Grid container direction={'row'} alignContent={'center'}>
                <Grid item xs={11}>
                  <Typography
                    variant={'caption'} style={{
                    whiteSpace: 'pre-line'
                  }}>{behaviour.title}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grow>
    </Grid>
  )
}

const mapStateToProps = ({ record }) => ({
  selectedCompetencies: selectSelectedCompetencies(record),
  epa: record.selectedEpa
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      select: recordActions.selectBehaviour
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Behaviour))
