import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const DialogTitleWithCloseIcon = withStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  closeButton: {
    color: theme.palette.grey[500],
    margin: 0,
  },
}))(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default DialogTitleWithCloseIcon;