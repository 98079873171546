import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';

const TaskAssignmentDialog = ({task, open, onClose}) => {
  if (!task) {
    return <></>
  }
  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>Aufgabenstellung für {task.title}</DialogTitle>
    <DialogContent>
      <div dangerouslySetInnerHTML={{__html: task.assignment}} />
    </DialogContent>
  </Dialog>
}

export default TaskAssignmentDialog