import { withTheme } from '@material-ui/core';
import * as moment from 'moment';
import React from 'react';
import { CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, YAxis } from 'recharts';

import RecordXAxis from './RecordXAxis';

const EpaScatterChart = props => {
  const { theme, items, handleClick } = props
  const colorsBySupervisionLevel = {
    0: '#ff6357',
    1: '#ff6357',
    2: '#ffc870',
    3: '#5bde74',
    4: '#5bde74',
    5: '#5bde74',
  }
  const { light } = theme.palette.secondary
  const data = items.map(item => ({
    id: item.id,
    record: item.record_id,
    epa: item.epa_number,
    title: `${item.case_title}\n${moment(item.observed_at).format('DD.MM.YYYY')}`,
    entrustmentLevel: item.supervision,
  }))
  return (
    <ResponsiveContainer width="99%" height={250}>
      <ScatterChart
        margin={{ top: 20, right: 20, bottom: 25, left: 10 }}>
        <CartesianGrid strokeDasharray="3 3"/>
        {RecordXAxis({})}
        <YAxis dataKey="entrustmentLevel" label={{ value: 'Supervisionslevel', position: 'insideBottomLeft', angle: -90, offset: 30}}
                textAnchor="end" name="Supervisionslevel" ticks={[0,1,2,3,4,5]} domain={[0, 5]}/>
        <ReferenceLine y={2} stroke="grey" strokeDasharray="3 3"/>
        <Scatter onClick={item => handleClick(item)}
                  name="Zeitlicher Entwicklungsverlauf" data={data}
                  fill={light}>
          {
            data.map((item, index) => {
              return <Cell key={`cell-${index}`} width={100} fill={colorsBySupervisionLevel[item.entrustmentLevel]} className="clickable"/>
            })
          }
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default withTheme(EpaScatterChart)