import types from './types';

const INITIAL_STATE = {
  rooms: [],
  room: '',
  error: '',
  machine: undefined,
  loading: false,
  currentStudent: undefined,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECT_ROOM:
      return {
        ...state,
        room: action.payload
      }
    case types.ROOM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.ROOM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.ROOM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: action.payload
      }
    case types.ROOM_GET_REQUEST:
      return {
        ...state, loading: true, error: ''
      }
    case types.ROOM_GET_FAILURE:
      return {
        ...state,
        error: action.payload,
        room: '',
        loading: false
      }
    case types.ROOM_GET_SUCCESS:
      return {
        ...state,
        room: action.payload,
        loading: false
      }
    case types.MACHINE_REQUEST:
      return {
        ...state, loading: true, error: ''
      }
    case types.MACHINE_FAILURE:
      return {
        ...state,
        error: action.payload,
        machine: null,
        loading: false
      }
    case types.MACHINE_SUCCESS:
      return {
        ...state,
        machine: action.payload,
        loading: false
      }
    case types.RESET:
      return {
        ...state,
        ...INITIAL_STATE
      }

    case types.SET_CURRENT_STUDENT:
      return {
        ...state,
        currentStudent: action.payload
      }
    default:
      return state
  }
}

export default reducer
