import types from './types'

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: '',
  active: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.OBSERVATION_ASSIGNMENTS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.OBSERVATION_ASSIGNMENTS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case types.OBSERVATION_ASSIGNMENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload
      }
    case types.OBSERVATION_ASSIGNMENTS_SET_ACTIVE:
      return {
        ...state,
        active: action.payload
      }
    default:
      return state
  }
}

export default reducer