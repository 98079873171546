import fetch from '../fetch';
import { recordActions, recordOperations } from '../records';
import actions from './actions';

const medforgeApiUrl = `${process.env.REACT_APP_MEDFORGE_URL}/api`

export const index = () => (dispatch, getState) => {
  dispatch(actions.taskListRequest())
  const url = `${medforgeApiUrl}/v1/tasks?fields[tasks]=id,title,linkedConceptualFrameworkItems,updatedAt,events&filter[isLocked]=false&sort=title`
  const user = getState().user.user
  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => {
      if (!response.ok) throw new Error('Fehlgeschlagen!')
      return response.json()
    })
    .then(tasks => {
      tasks = tasks.data
        .map(mapApiTask)
        .filter(t => !!t.linked_conceptual_framework_items.length)
      dispatch(actions.taskListSuccess(tasks))
      return tasks
    })
    .catch(error => {
      dispatch(
        actions.taskListFailure(error)
      )
    })
}

const mapApiTask = apiTask => ({
  id: apiTask.id,
  title: apiTask.attributes.title,
  linked_conceptual_framework_items: apiTask.attributes.linkedConceptualFrameworkItems.filter(lcfi => ['core-epa', 'custom-epa', 'social-competencies', 'mini-cex'].includes(lcfi.type)),
  events: apiTask.attributes.events,
})

export const select = task => dispatch => {
  dispatch(actions.selectTask(task))
  if (task && task.linked_conceptual_framework_items.length > 0) {
    dispatch(recordOperations.indexHistory(task))
    const epaIds = task.linked_conceptual_framework_items.map(epa => epa.id)
    dispatch(recordActions.setTaskEpas(epaIds))
  }
  return task
}

export const selectMultiple = tasks => dispatch => {
  dispatch(actions.selectTasks(tasks))
  return tasks
}

export const selectById = taskId => (dispatch, getState) => {
  dispatch(actions.taskGetRequest())
  const url = `${medforgeApiUrl}/v1/tasks/${taskId}?fields[tasks]=id,title,linkedConceptualFrameworkItems,updatedAt,events`
  const user = getState().user.user
  return fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + user['accessToken']
    }
  })
    .then(response => response.json())
    .then(apiResponse => {
      const task = mapApiTask(apiResponse.data)
      dispatch(actions.taskGetSuccess(task))
      return task
    })
    .catch(error => {
      dispatch(
        actions.taskGetFailure(error)
      )
    })
}

export default { select, selectMultiple, selectById, index }
