import types from './types'


export const actions = {
  reset: () => ({
    type: types.RESET
  }),

  selectTasks: tasks => ({
    type: types.SELECT_TASKS,
    payload: tasks
  }),

  selectTask: task => ({
    type: types.SELECT_TASK,
    payload: task
  }),

  taskListRequest: () => ({
    type: types.TASK_LIST_REQUEST
  }),

  taskListFailure: error => ({
    type: types.TASK_LIST_FAILURE,
    payload: error
  }),

  taskListSuccess: list => ({
    type: types.TASK_LIST_SUCCESS,
    payload: list
  }),

  taskGetRequest: () => ({
    type: types.TASK_GET_REQUEST
  }),

  taskGetFailure: error => ({
    type: types.TASK_GET_FAILURE,
    payload: error
  }),

  taskGetSuccess: task => ({
    type: types.TASK_GET_SUCCESS,
    payload: task
  })

}

export default actions
