import { withTheme } from '@material-ui/core';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const ComparisonChart = props => {
  const { theme, items, activeItems } = props
  const { main } = theme.palette.secondary
  const data = items
    .filter(c => activeItems.includes(`epa-${c.epaId}`))
    .map(c => ({ ...c, epaLabel: `EPA ${c.epaId}` }))
  return (
    <ResponsiveContainer width="99%" height={250}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="epaLabel" interval={0}/>
        <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]}/>
        <Legend payload={[{
          value: 'Ø-Supervisionslevel des Lernenden',
          type: 'square',
          color: main
        }, {
          value: 'Ø-Supervisionslevel der Kohorte',
          type: 'square',
          color: '#adadad'
        }]}
        />
        <Bar dataKey="average" fill={main} minPointSize={5}
            label={{ position: 'top', fontSize: 10 }}>
          {
            data.map((_, index) => (
              <Cell key={`cell-${index}`}/>
            ))
          }
        </Bar>
        <Bar dataKey="cohortsAverage" fill={'#adadad'}
            label={{ position: 'top', fontSize: 10 }}>
          {
            data.map((_, index) => (
              <Cell key={`cell-${index}`}/>
            ))
          }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default withTheme(ComparisonChart)