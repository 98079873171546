import { colors } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { connect } from 'react-redux';

import epaColumns from '../../assets/epaColumns';
import Behaviour from './Behaviour';
import Category from './Column';
import Competency from './Competency';
import EpaSupervisionBlock from './EpaSupervisionBlock';
import SaveButton from './SaveButton';

const styles = theme => ({
  bar: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  form: {
    borderTop: '2px solid',
    borderColor: colors.grey[300],
    marginTop: '1rem',
    paddingTop: '1rem',
  },
  formContainer: {
    borderRadius: 4
  },
  badge: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginRight: theme.spacing(0.75),
    borderRadius: 4
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
    lineHeight: 1.2
  }
})

class EpaForm extends React.Component {

  render() {
    const { classes, form, task } = this.props
    const columns = epaColumns.de
    return (
      form && task ?
        <Grid container direction={'row'} className={classes.bar}>
          <Grid item xs={3}>
            <Typography variant={'h6'} className={classes.title}>{form.title}</Typography>
          </Grid>
          <Grid item xs={1}/>
          <Grid item xs={4} className={classes.title}>
            <EpaSupervisionBlock/>
          </Grid>

          <Grid item xs={12} className={classes.form}>
            <Grid container direction={'column'} className={classes.formContainer}>
              <Category columns={columns}/>
              {form.competencies.map(competency =>
                <Competency key={competency.id} competency={competency}>
                  {
                    form.behaviours
                      .filter(behaviour => behaviour.competency_id === competency.id)
                      .map((behaviour, index) => <Behaviour key={`${competency.id}-${behaviour.value}`}
                                                     competency={competency}
                                                     behaviour={behaviour}
                                                     column={columns[index + 1]}/>)
                  }
                </Competency>
              )}
            </Grid>
          </Grid>
          <SaveButton/>
        </Grid> : null
    )
  }
}

const mapStateToProps = ({ record, task }) => ({
  form: record.selectedEpa,
  task: task.task,
})

export default connect(
  mapStateToProps
)(withStyles(styles)(EpaForm))
