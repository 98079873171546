import { withStyles } from '@material-ui/core';
import MuiTablePagination from '@material-ui/core/TablePagination';
import React from 'react';

const styles = theme => ({
  paginationWrapper: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
})

const TablePagination = ({totalCount, pageSize, pageNumber, onPageChange, onRowsPerPageChange, classes}) => {
  return <MuiTablePagination
    rowsPerPageOptions={[5, 10, 20, 50]}
    labelRowsPerPage="Zeilen pro Seite:"
    component="div"
    count={totalCount}
    rowsPerPage={pageSize}
    page={pageNumber - 1}
    backIconButtonProps={{
      'aria-label': 'previous page',
    }}
    nextIconButtonProps={{
      'aria-label': 'next page',
    }}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
    className={classes.paginationWrapper}
  />
}

export default withStyles(styles)(TablePagination)