import types from './types';

const INITIAL_STATE = {
  tasks: [],
  taskOptions: [],
  task: '',
  error: '',
  loading: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECT_TASK:
      return { ...state, error: '', task: action.payload, taskOptions: [] }
    case types.SELECT_TASKS:
      return { ...state, error: '', task: '', taskOptions: action.payload }
    case types.TASK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.TASK_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case types.TASK_LIST_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      }
    case types.TASK_GET_REQUEST:
      return { ...state, loading: true, error: '' }
    case types.TASK_GET_SUCCESS:
      return { ...state, loading: false, task: action.payload }
    case types.TASK_GET_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case types.RESET:
      return {
        ...state,
        ...INITIAL_STATE
      }
    default:
      return state
  }
}

export default reducer