import { IconButton } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import React from 'react';

const StyledTableCell = withStyles(theme => ({
  head: {
    fontWeight: 600
  },
}))(TableCell);

const tableHeadStyles = theme => ({
  lastCellWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  filterIndication: {
    textDecoration: 'underline',
  }
})

const TableHead = ({columns, orderBy, orderDirection, query, filter, onOrderChange, onQueryChange, onResetFilter, classes}) => {
  const handleOrderChangeFn = nextOrderBy => () => {
    const nextOrderDirection = orderBy === nextOrderBy ? orderDirection === 'desc' ? 'asc' : 'desc' : orderDirection;
    onOrderChange({
      orderBy: nextOrderBy,
      orderDirection: nextOrderDirection
    })
  }
  return <MuiTableHead>
    <TableRow>
      {columns.map(column => (
        <StyledTableCell
          key={column.id}
          sortDirection={column.orderBy && orderBy === column.orderBy ? orderDirection : false}
          className={column.filtered ? classes.filterIndication : ''}
        >
          {column.orderBy ?
            <TableSortLabel
              active={orderBy === column.orderBy}
              direction={orderDirection}
              onClick={handleOrderChangeFn(column.orderBy)}
            >
              {column.label}
            </TableSortLabel> :
            column.label
          }
        </StyledTableCell>
      ))}
      <TableCell align="right">
        <div className={classes.lastCellWrapper}>
          <Input
            onChange={onQueryChange}
            value={query}
            placeholder="Suche (mind. 3 Zeichen)"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          {(filter || query) && <Tooltip title="Filter zurücksetzen"><IconButton size="small" onClick={onResetFilter}><SettingsBackupRestoreIcon/></IconButton></Tooltip>}
        </div>
      </TableCell>
    </TableRow>
  </MuiTableHead>
}

export default React.memo(withStyles(tableHeadStyles)(TableHead), (prevProps, nextProps) => {
  return ![
    'orderBy',
    'orderDirection',
    'query',
    'filter',
  ]
    .filter(key => prevProps[key] !== nextProps[key])
    .length
})