import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';

const ConfirmDestroyItemDialog = ({open, onClose, perspective, item}) => {
  perspective = perspective || 'recipient'
  const title = perspective === 'creator' ? 'Auftrag wirklich löschen?' : 'Auftrag wirklich ablehnen?'
  const content = perspective === 'creator' ? <span>Anschließend wird der Auftrag aus dieser Liste und der Liste von <i>{item.assignedTo.name}</i> entfernt.</span> : 'Anschließend wird der Auftrag aus Ihrer Liste gelöscht.'
  return <Dialog open={open} onClose={() => onClose(false)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      <Button autoFocus onClick={() => onClose(false)} color="primary">Nein</Button>
      <Button onClick={() => onClose(true)} color="primary">Ja</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmDestroyItemDialog