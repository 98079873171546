import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Dialog from '@material-ui/core/Dialog/Dialog';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

import EpaPanel from './EpaPanel';

const styles = theme => ({
  appBar: {
    boxShadow: 'none'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
})

const RecordDialog = withStyles(styles, { withTheme: true })(props => {
  const { record, item, onClose, classes, ...rest } = props
  return (
    <Dialog fullScreen open={!!record} onClose={onClose} {...rest}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <AppBar className={classes.appBar} position="relative">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}
                        aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Beobachtung
            </Typography>
          </Toolbar>
        </AppBar>
        <EpaPanel record={record} item={item}/>
      </div>
    </Dialog>
  )
})


export default RecordDialog