import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';

import epasJson from '../../assets/epas';

const styles = theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
})

const ComparisonChart = props => {
  const { theme, classes, items, activeItems } = props
  const { light, main } = theme.palette.secondary
  const data = items
    .filter(c => activeItems.includes(`epa-${c.epaId}`))
  return (<Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={6}>
        <ResponsiveContainer width="99%" height={250}>
          <RadarChart outerRadius={90} data={data}>
            <PolarGrid/>
            <PolarAngleAxis dataKey="epaId"/>
            <PolarRadiusAxis angle={30} domain={[0, 5]} tickCount={6}/>
            <Radar dataKey="average" stroke={main}
                  fill={light} fillOpacity={0.6}/>
          </RadarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={6} className={classes.grid}>
          {data.map(epa => (
            <div key={epa.epaId}>
              <Tooltip title={epasJson.find(ej => ej.id === epa.epaId).title}>
                <Typography variant="subtitle2" noWrap>
                  EPA {epa.epaId}
                </Typography>
              </Tooltip>
              <Typography variant="caption" noWrap>
                n: {epa.count}, Ø: {parseFloat(epa.average).toFixed(2)}
              </Typography>
            </div>
          ))}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles, { withTheme: true })(ComparisonChart)