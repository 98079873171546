import actions from './actions';
import { index as apiIndex, destroy as apiDestroy } from './api';

export const index = () => (dispatch, getState) => {
  const user = getState().user.user

  dispatch(actions.observationAssignmentsRequest())
  return apiIndex(user)
    .then(result => {
      dispatch(actions.observationAssignmentsSuccess(result))
      return result;
    })
    .catch(error => {
      dispatch(actions.observationAssignmentsFailure(error))
    })
}

export const destroy = observationAssignment => (dispatch, getState) => {
  const user = getState().user.user
  const list = getState().observationAssignment.list
  dispatch(actions.observationAssignmentsRequest())
  return apiDestroy(user, observationAssignment._id)
    .then(_ => {
      dispatch(actions.observationAssignmentsSuccess(list.filter(item => item._id !== observationAssignment._id)))
    })
    .catch(error => {
      dispatch(actions.observationAssignmentsFailure(error))
    })
}

export default { index, destroy }