import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    borderRadius: '50%',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  imgHover: {
    width: '120px',
    height: '140px',
    position: 'fixed',
    right: '50px',
    top: '60px',
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    zIndex: '1',
    pointerEvents: 'none',
    objectFit: 'cover',
  }
}))

const Photo = ({ student }) => {
  if (!student || !student.photo) {
    return null;
  }

  const classes = useStyles();
  const [hover, setHover] = useState(false)
  const toggleHover = () => setHover(!hover)

  return (
    <>
      <div className={classes.root} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
        <img className={classes.img} alt="Profilfoto" src={`data:image/png;base64,${student.photo}`}/>
      </div>
      { hover ? <img className={classes.imgHover} alt="Profilfoto" src={`data:image/png;base64,${student.photo}`}/> : null}
    </>
  )
}

export default Photo