import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sortByFn } from '../../commons/helpers';
import { observationAssignmentActions, observationAssignmentOperations } from '../../ducks/observation-assignments';
import ConfirmDestroyItemDialog from './ConfirmDestroyItemDialog';
import CustomListItem from './CustomListItem';
import SortBySelect from './SortBySelect';
import TaskAssignmentDialog from './TaskAssignmentDialog';

const useStyles = makeStyles(theme => ({
  background: {
    overflow: 'auto',
    height: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`
  },
  card: {
    maxWidth: '1000px',
    margin: '0 auto',
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    },
  },
  cardContent: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%'
  }
}))

function ObservationAssignments(props) {
  const classes = useStyles()
  const {user, loading, list, setActive, openTaskById, loadList, destroyItem} = props
  const [taskAssignmentDialogOpen, setTaskAssignmentDialogOpen] = useState(false);
  const [destroyItemDialogPerspective, setDestroyItemDialogPerspective] = useState(null);
  const [taskForDialog, setTaskForDialog] = useState(null);
  const [itemToDestroy, setItemToDestroy] = useState(null);
  const [sortByRecipient, setSortByRecipient] = useState('');
  const [sortByCreator, setSortByCreator] = useState('');

  useEffect(() => {
    loadList(user)
  }, [user])

  const handleListItemClick = (event, item) => {
    setActive(item)
    openTaskById(item.task.id)
  }

  const handleTaskAssignmentClick = (event, item) => {
    if (!item.task.assignment) {
      return
    }
    setTaskForDialog(item.task)
    setTaskAssignmentDialogOpen(true)
  }

  const handleListItemDelete = (event, item, perspective) => {
    setItemToDestroy(item)
    setDestroyItemDialogPerspective(perspective)
  }

  const handleTaskAssignmentDialogClose = () => {
    setTaskAssignmentDialogOpen(false)
  }

  const handleDestroyItemDialogClose = response => {
    setDestroyItemDialogPerspective(null)
    if (response === true) {
      destroyItem(itemToDestroy)
    }
    setItemToDestroy(null)
  }

  const handleSortChange = setState => event => {
    setState(event.target.value)
  }

  const listAssignedToUser = list.filter(item => item.assignedTo.id === user.id.toString()).toSorted(sortByFn(sortByRecipient))
  const listAssignedByUser = list.filter(item => item.assignedBy.id === user.id.toString()).toSorted(sortByFn(sortByCreator))

  return <Grid
    className={classes.background}
  >
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column">
          <Typography variant="h4" component="h1" gutterBottom>Beobachtungsaufträge</Typography>
          <Typography variant="body1" paragraph>
            Hier sind alle offenen Aufträge für Beobachtungen aufgelistet. Über die Icons können Sie die Video- und Papierdokumentation der Studierenden aufrufen (sofern vorhanden). Sobald eine hier gelistete Beobachtung erstellt wurde, wird der Auftrag aus der Liste entfernt.
          </Typography>
          <Grid container direction="column" alignItems='flex-end'>
            { listAssignedToUser.length > 2 ? <SortBySelect sortBy={sortByRecipient} onChange={handleSortChange(setSortByRecipient)} perspective="recipient"/> : null}
          </Grid>
          <List className={classes.list}>
            { loading ?
              <>
                <Skeleton animation='wave' width={400} height={50} />
                <Skeleton animation='wave' width={400} height={50} />
              </> :
                listAssignedToUser.length ?
                listAssignedToUser.map(item => <CustomListItem key={item._id} item={item}
                  onClick={handleListItemClick} onTaskAssignmentClick={handleTaskAssignmentClick} onDelete={handleListItemDelete}/>) :
                  <ListItem>Keine offenen Aufträge vorhanden</ListItem>
            }
          </List>
        </Grid>
      </CardContent>
    </Card>
    { listAssignedByUser.length ? <Card className={classes.card}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore/>}><Typography variant='h6'>Erstellte Beobachtungsaufträge (unbearbeitet)</Typography></AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column" alignItems='flex-end'>
              <Grid item>
                { listAssignedByUser.length > 2 ? <SortBySelect sortBy={sortByCreator} onChange={handleSortChange(setSortByCreator)} perspective="creator"/> : null}
              </Grid>
              <List className={classes.list}>
                { listAssignedByUser.map(item => <CustomListItem key={item._id} item={item} perspective="creator"
                  onTaskAssignmentClick={handleTaskAssignmentClick} onDelete={handleListItemDelete}/>)}
              </List>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Card> : null}
    <TaskAssignmentDialog task={taskForDialog} open={taskAssignmentDialogOpen} onClose={handleTaskAssignmentDialogClose}/>
    <ConfirmDestroyItemDialog open={!!destroyItemDialogPerspective} item={itemToDestroy} perspective={destroyItemDialogPerspective} onClose={handleDestroyItemDialogClose}/>
  </Grid>
}

const mapStateToProps = ({user, observationAssignment}) => ({
  user: user.user,
  list: observationAssignment.list,
  loading: observationAssignment.loading,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadList: observationAssignmentOperations.index,
    destroyItem: observationAssignmentOperations.destroy,
    setActive: observationAssignmentActions.setActive,
    openTaskById: taskId => push(`/tasks/${taskId}/forms`),
  },
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(ObservationAssignments)
