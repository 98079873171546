const BASE_PATH = 'api/tasks/'

const types = {

  SELECT_TASK: BASE_PATH + 'SELECT_TASK',
  SELECT_TASKS: BASE_PATH + 'SELECT_TASKS',
  TASK_LIST_REQUEST: BASE_PATH + 'TASK_LIST_REQUEST',
  TASK_LIST_FAILURE: BASE_PATH + 'TASK_LIST_FAILURE',
  TASK_LIST_SUCCESS: BASE_PATH + 'TASK_LIST_SUCCESS',
  TASK_GET_REQUEST: BASE_PATH + 'TASK_GET_REQUEST',
  TASK_GET_SUCCESS: BASE_PATH + 'TASK_GET_SUCCESS',
  TASK_GET_FAILURE: BASE_PATH + 'TASK_GET_FAILURE',
  RESET: BASE_PATH + 'RESET_TASK'
}

export default types
