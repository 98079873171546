import fetch from '../fetch';

const mongoUrl = process.env.REACT_APP_BACKEND_URL
export const index = async user => {
  const url = `${mongoUrl}/observation-assignments`
  const response = await fetch(url, {
    method: 'GET',
    headers: defaultHeaders(user)
  })
  const json = await handleResponse(response)
  return json
}

export const destroy = async (user, id) => {
  const url = `${mongoUrl}/observation-assignments/${id}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: defaultHeaders(user)
  })
  const json = await handleResponse(response)
  return json
}

const defaultHeaders = user => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + user['accessToken']
})

const handleResponse = response => {
  if (!response.ok) throw new Error(response.statusText)
  return response.json()
}