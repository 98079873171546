import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';

import config from '../../config/config';
import About from '../about';
import ObservationAssignments from '../observation-assignments';
import LoginForm from '../auth';
import NotFound from '../auth/NotFound';
import { PrivateRoute } from '../auth/PrivateRoute';
import Disclaimer from '../disclaimer';
import Form from '../form';
import ProgressControl from '../progress';
import Record from '../record';
import Room from '../room';


class App extends React.Component {

  render() {
    const { loading, user } = this.props
    const { requiredPermissions } = config
    return loading ? <LinearProgress color={'secondary'}/> :
      <Switch>
        <Redirect exact from="/" to="/login"/>
        <Route exact path="/login" component={LoginForm}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/disclaimer" component={Disclaimer}/>
        <PrivateRoute exact header={true} path="/rooms" component={Room} user={user} permissions={requiredPermissions.room} title="Raum-/Fallauswahl" module="Bewertungmodul"/>
        <PrivateRoute exact header={true} path="/tasks/:caseId/forms/:recordId?" component={Form} user={user} permissions={requiredPermissions.form} title="Beobachtungsmodus" module="Bewertungmodul"/>
        <PrivateRoute exact header={true} path="/records" component={Record} user={user} permissions={requiredPermissions.record} title="Beobachtungsübersicht" module="Bewertungmodul"/>
        <PrivateRoute exact header={true} path="/observation-assignments" component={ObservationAssignments} user={user} permissions={requiredPermissions.createRecord} title="Beobachtungsaufträge" module="Bewertungmodul"/>
        <PrivateRoute exact header={true} path="/progress-control/:studentId?"
                      component={ProgressControl} user={user} permissions={requiredPermissions.progressControl} title="EPA-Dashboard" module="Dashboardmodul"/>
        <Route component={NotFound}/>
      </Switch>
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  loading: user.loading
})

export default connect(mapStateToProps)(App)
