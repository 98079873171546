import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 250
  },
})

const CustomMultiSelection = props => {
  const { classes, options, selected, setSelected, label, renderValue, renderValueSingular } = props

  const handleChange = (event) => {
    const values = event.target.value;
    if (values.includes('all')) {
      setSelected(options.map(option => option.name));
    } else if (values.includes('none')) {
      setSelected([]);
    } else {
      setSelected(values);
    }
  };

  const selectedOptions = options.filter(option => selected.includes(option.name));

  const renderValueFn = _ => {
    const count = selectedOptions.length
    if (count === 0) {
      return renderValue.replace('#count', 'Keine')
    }
    if (count === options.length) {
      return renderValue.replace('#count', 'Alle')
    }
    const value = count === 1 && renderValueSingular ? renderValueSingular : renderValue
    return value.replace('#count', count)
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="custom-multi-selection">{ label }</InputLabel>
      <Select
        name="custom-multi-selection"
        disabled={options.length === 0}
        multiple
        value={selected}
        onChange={handleChange}
        input={<Input />}
        renderValue={renderValueFn}
      >
        <MenuItem value="all">
          <em>Alle</em>
        </MenuItem>
        <MenuItem value="none">
          <em>Keine</em>
        </MenuItem>
        <hr/>
        {options.map((option) => {
          const checked = selected.includes(option.name)
          return (
          <MenuItem key={option.name} value={option.name}>
            <Checkbox checked={checked} />
            <ListItemText primary={option.title} />
          </MenuItem>
        )})}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(CustomMultiSelection)