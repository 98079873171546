import { withTheme } from '@material-ui/core';
import * as moment from 'moment';
import React from 'react';
import { CartesianGrid, Cell, ResponsiveContainer, Scatter, ScatterChart, YAxis } from 'recharts';

import RecordXAxis from './RecordXAxis';

const CustomScatterChart = props => {
  const { theme, items, handleClick } = props
  const { light } = theme.palette.secondary
  const data = items.map(item => ({
    id: item.id,
    record: item.record_id,
    epa: item.epa_number,
    title: `${item.case_title}\n${moment(item.observed_at).format('DD.MM.YYYY')}`,
    value: item.value,
  }))
  return (
    <ResponsiveContainer width="99%" height={250}>
      <ScatterChart
        margin={{ top: 20, right: 20, bottom: 25, left: 10 }}>
        <CartesianGrid strokeDasharray="3 3"/>
        {RecordXAxis({})}
        <YAxis dataKey="value" label={{ value: 'Ausprägungsgrad', position: 'insideBottomLeft', angle: -90, offset: 30}}
                textAnchor="end" name="Ausprägungsgrad" ticks={[1,2,3,4,5,6]} domain={[1, 6]}/>
        <Scatter onClick={item => handleClick && handleClick(item)}
                  name="Zeitlicher Entwicklungsverlauf" data={data}
                  fill={light}>
          {
            data.map((_, index) => {
              return <Cell key={`cell-${index}`} width={100} className="clickable"/>
            })
          }
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default withTheme(CustomScatterChart)