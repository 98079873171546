import { colors } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';
import React from 'react';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const StyledTabs = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  flexContainer: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)'
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main
  }
}))(Tabs)

const StyledTab = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      minWidth: theme.spacing(25)
    },
    textTransform: 'none',
    // fontSize: '.875rem',
    lineHeight: '18px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    // fontWeight: 400,
    // marginRight: theme.spacing(4),
    color: '#343434',
    backgroundColor: colors.orange[50],
    // fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    '&:last-child': {
      borderRight: 'none',
    },
    '&:hover': {
      color: colors.orange[900],
      // backgroundColor: '#fbfbfb',
      // opacity: 1
    },
    '&$selected': {
      color: colors.orange[900],
      backgroundColor: colors.orange[100],
      // fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: colors.orange[900],
    },
    '&.has-errors': {
      fontWeight: 'bold',
      color: colors.red[900],
      '& span:first-child::after': {
        content: '"⚠"',
        position: 'absolute',
        right: '0.2rem',
        top: '0.1rem',
      },
    }
  },
  selected: {}
}))(props => <Tab {...props} />)

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  navbar: {
    height: '100%',
    overflowY: 'scroll'
  },
})

const TabContainer = props => {
  const { classes, value, list, onChange, children, validationErrors } = props

  const handleChange = (event, newValue) => {
    onChange(newValue)
  }

  return (
    <div id="navbar" className={classes.navbar}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {list.map(epa => {
          let title = `EPA ${epa.id}`
          if (epa.short_title) {
            title += `: ${epa.short_title}`
          }
          if (epa.id >= 14) {
            title = epa.short_title
          }
          const hasErrors = !!validationErrors.find(error => error.itemTypeId === epa.id)
          return <StyledTab key={epa.id} label={title} className={clsx({'has-errors': hasErrors})} {...a11yProps(epa.id)}/>
        })}
      </StyledTabs>
      {children}
    </div>
  )
}

export default withStyles(styles)(TabContainer)
