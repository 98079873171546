import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

import epaJson from '../../assets/epas';
import MiniCexCompetency from '../../containers/form/MiniCexCompetency';
import SocialCompetency from '../../containers/form/SocialCompetency';
import Item from './Item';
import Metadata from './Metadata';

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  panel: {
    '&$disabled': {
      backgroundColor: 'unset',
      boxShadow: 'unset'
    },
  },
  disabled: {
    backgroundColor: 'unset'
  },
  heading: {
    marginRight: theme.spacing(1)
  },
  epa: {
    backgroundColor: '#efefef',
    borderRadius: 4,
    color: theme.palette.secondary.dark,
    padding: `0px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    fontWeight: theme.typography.fontWeightMedium
  }
}))

const RecordLine = props => {
  const classes = useStyles()
  const { summary, children, disabled, expanded } = props;
  return <Accordion defaultExpanded={!disabled && expanded} classes={{ root: classes.panel, disabled: classes.disabled }} disabled={disabled}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        { summary }
      </AccordionSummary>
      {children &&
      <AccordionDetails>
        { children }
      </AccordionDetails>}
    </Accordion>
}

const RecordLineSummary = props => {
  const classes = useStyles()
  const { title, subtitle, content, children } = props
  return (
    <Grid container justifyContent={'space-between'} wrap="nowrap">
      <Grid item style={{flexShrink: 0}}>
        <Grid container>
          <Typography variant={'body2'} className={`${classes.heading} ${classes.epa}`}>{ title }</Typography>
          {subtitle && <Typography variant={'body2'} className={classes.heading}>{ subtitle }</Typography>}
        </Grid>
      </Grid>
      <Grid item>
        { content && <Typography variant={'body2'}>{ content }</Typography> }
        { children }
      </Grid>
    </Grid>
  )
}

const RecordEpaLine = props => {
  const { epa, expanded } = props
  const supervision = epa.supervision === null ? '-' : epa.supervision

  const summary = <RecordLineSummary
    title={`EPA ${epa.id}`}
    subtitle={epa.title}
    content={`Supervisionslevel: ${supervision}`}/>

  const content = (epa.competencies || [])
    .sort((a, b) => a.competencyId - b.competencyId)
    .map(({competencyId, title, possibilities, color, value}) => (
      <Grid key={competencyId} item>
        <Typography variant={'caption'}>Kompetenzebene {competencyId}: {title}</Typography>
        <Grid container direction={'row'}>
          {possibilities
            .map(p => <Item key={`${p.competencyId}.${p.value}`} color={color} isSelected={value === p.value} title={p.title}/>
          )}
        </Grid>
      </Grid>
    ))

  return <RecordLine summary={summary} disabled={content.length === 0} expanded={expanded}>
    <Grid container direction="column">{ content }</Grid>
  </RecordLine>
}

const RecordSocialCompetencyLine = props => {
  const { competencies, expanded } = props;

  const summary = <RecordLineSummary title="Soziale Kompetenzen">
      <Grid container spacing={1}>
        {competencies.map(({ id, title, value }) =>
          <Grid item key={id}><Typography variant={'body2'}>{title}: {value > 0 ? value : '-'}</Typography></Grid>
        )}
      </Grid>
    </RecordLineSummary>

  return <RecordLine summary={summary} expanded={expanded}>
    <Grid container direction="column">
      {competencies.map(competency => <SocialCompetency key={competency.id} competency={competency} disabled={true}/>)}
    </Grid>
  </RecordLine>
}

const RecordMiniCexLine = props => {
  const { competencies, role, expanded } = props;

  const summary = <RecordLineSummary title="Mini-CEX Items">
      <Grid container spacing={1}>
        {competencies.map(({ id, title, value }) =>
          <Grid item key={id}><Typography variant={'body2'}>{title}: {value > 0 ? value : '-'}</Typography></Grid>
        )}
        <Grid item><Typography variant={'body2'}>Rolle: { role || 'n.A.' }</Typography></Grid>
      </Grid>
    </RecordLineSummary>

  return <RecordLine summary={summary} expanded={expanded}>
    <Grid container direction="column">
      {competencies.map(competency => <MiniCexCompetency key={competency.id} competency={competency} disabled={true}/>)}
    </Grid>
  </RecordLine>
}

const getEpaDefinition = epa => epaJson.find(epaDefinition => epaDefinition.id === epa)

export default function EpaPanel(props) {
  const classes = useStyles()
  const { record, item: selectedItem } = props

  let metadata, accordions;
  if (record) {
    const room = record.room && record.room.title
    const student = `${record.student.firstName} ${record.student.lastName}`
    const observer = `${record.observer.firstName} ${record.observer.lastName}`
    metadata = <Metadata case={record.case.title} room={room} student={student} observer={observer} createdAt={record.createdAt} observedAt={record.observedAt} rating={record.global_rating}/>;
    if (record.epas) {
      const validEpas = record.epas
        .filter(epa => epa.id < 14)
        .filter(epa => epa.supervision_level !== null)
        .map(epa => {
          const epaDefinition = getEpaDefinition(epa.id)
          return {
            ...epa,
            title: epaDefinition.title,
            competencies: (epa.competencies || []).map(competency => {
              const {title, color} = epaDefinition.competencies.find(co => co.id === competency.competencyId)
              const possibilities = epaDefinition.behaviours.filter(b => b.competency_id === competency.competencyId)
              return {
                ...competency,
                title,
                color,
                possibilities,
              }
            })
          }
        })
      accordions = validEpas.map(epa => <RecordEpaLine epa={epa} key={epa.id} expanded={validEpas.length === 1 || selectedItem === epa.id}></RecordEpaLine>)
      const socialCompetencies = record.epas.find(epa => epa.id === 14)
      if (socialCompetencies) {
        const socialCompetenciesDefinition = getEpaDefinition(socialCompetencies.id)
        const competencies = socialCompetenciesDefinition.competencies.map(competency => ({
          ...((socialCompetencies.competencies || []).find(c => c.competencyId === competency.id) || {}),
          ...competency,
        }))
        accordions.push(<RecordSocialCompetencyLine competencies={competencies} key={socialCompetencies.id} expanded={validEpas.length === 0 || selectedItem === socialCompetencies.id}/>)
      }
      const miniCexCompetencies = record.epas.find(epa => epa.id === 15)
      if (miniCexCompetencies) {
        const miniCexCompetenciesDefinition = getEpaDefinition(miniCexCompetencies.id)
        const competencies = miniCexCompetenciesDefinition.competencies.map(competency => ({
          ...((miniCexCompetencies.competencies || []).find(c => c.competencyId === competency.id) || {}),
          ...competency,
        }))
        const { role } = record
        accordions.push(<RecordMiniCexLine competencies={competencies} role={role} key={miniCexCompetencies.id} expanded={validEpas.length === 0 || selectedItem === miniCexCompetencies.id}/>)
      }
    }
  }
  return (
    <div className={classes.root}>
      {metadata}
      {accordions}
    </div>
  )
}