import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

import DialogTitleWithCloseIcon from '../../commons/DialogTitleWithCloseIcon';

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function GlobalRatingDialog(props) {
  useEffect(() => {
    !props.open && setGlobalRating(props.value)
  })
  const [globalRating, setGlobalRating] = useState({
    globalRating: props.value || '',
  })
  const handleChange = event => setGlobalRating(event.target.value)
  const handleClose = () => props.onClose(globalRating)
  return (
    <Dialog onClose={handleClose} aria-labelledby="global-rating-dialog-title" open={props.open} maxWidth="sm" fullWidth>
      <DialogTitleWithCloseIcon id="global-rating-dialog-title" onClose={handleClose}>
        Allgemeine Bewertung / Anmerkungen
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <TextField
          label="Freitext"
          onChange={handleChange}
          value={globalRating}
          multiline
          minRows="2"
          maxRows="12"
          fullWidth
        />
      </DialogContent>
    </Dialog>
  )
}
