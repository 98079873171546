import { Card, colors, Typography } from '@material-ui/core';
import color from '@material-ui/core/colors/orange';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';

import { selectSelectedCompetencies, selectVisibleValidationErrors } from '../../ducks/records/reducers';
import MiniCexCompetency from './MiniCexCompetency';
import RoleSelection from './RoleSelection';
import SaveButton from './SaveButton';

const styles = theme => ({
  bar: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
  card: {
    padding: '15px',
    marginBottom: '10px',
    background: color[50],
  },
  hasErrors: {
    border: '2px solid',
    borderColor: colors.red[900],
  }
})

class MiniCexForm extends React.Component {
  render() {
    const { classes, form, selectedCompetencies, task, recordValidationErrors } = this.props

  const hasRoleError = recordValidationErrors.find(error => error.property === 'role')
    return (
      form && task ?
        <Grid container className={classes.bar}>
          <Grid container item xs={12} direction="column">
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="h4">{ form.title }</Typography>
                <Typography variant="subtitle1" paragraph>{ form.description }</Typography>
              </Grid>
              <Grid item>
                <Card elevation={1} className={clsx(classes.card, hasRoleError && classes.hasErrors)}>
                  <RoleSelection/>
                </Card>
              </Grid>
            </Grid>
            {form.competencies.map(competency => {
              const selectedCompetency = selectedCompetencies.find(c => c.competencyId === competency.id) || {}
              return <MiniCexCompetency key={competency.id} competency={{...competency, value: selectedCompetency.value || null}}/>
            })}
          </Grid>
          <SaveButton/>
        </Grid> : null
    )
  }
}

const mapStateToProps = ({ task, record }) => ({
  form: record.selectedEpa,
  selectedCompetencies: selectSelectedCompetencies(record),
  task: task.task,
  recordValidationErrors: selectVisibleValidationErrors(record)
})

export default connect(
  mapStateToProps
)(withStyles(styles)(MiniCexForm))
