import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { forwardRef } from 'react';

import DialogTitleWithCloseIcon from '../../commons/DialogTitleWithCloseIcon';

const useStyles = makeStyles({
  runningTask: {
    borderLeft: '10px solid green'
  }
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: theme.spacing(1),
  }
}))(MuiDialogContent)

const TaskButton = withStyles(() => ({
  root: {
    textTransform: 'inherit',
    letterSpacing: 'inherit',
    textAlign: 'left',
    alignItems: 'start'
  }
}))(Button)

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const TaskSelectDialogInner = props => {
  const classes = useStyles()
  const { onClose, tasks, room, open, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleSelect = task => () => onClose(task)

  const taskList = tasks.map(task => {
    const now = moment()
    const currentlyRunning = !!(task.events && task.events.find(event => now.unix() >= event.starts_at - 30*60 && now.unix() <= event.ends_at))
    return <TaskButton key={task.id} onClick={handleSelect(task, room)} variant='contained' className={clsx({[classes.runningTask]: currentlyRunning})}>{task.title}: {task.description}</TaskButton>
  })

  if (!room) {
    return null
  }

  return (
    <Dialog onClose={handleCancel} aria-labelledby="task-select-dialog-title" open={open} maxWidth="xs" {...other}>
      <DialogTitleWithCloseIcon id="task-select-dialog-title" onClose={handleCancel}>
        Fallauswahl Raum <b>{room.title}</b>
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <Typography variant='body1'>Für den Raum {room.title} sind <b>mehrere Fälle</b> verfügbar. Laufende Fälle sind grün markiert. Für welchen Fall möchten Sie eine Beobachtung erstellen?</Typography>
        {taskList}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default function TaskSelectDialog(props) {
  return <TaskSelectDialogInner TransitionComponent={Transition} {...props}/>
}
