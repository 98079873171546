import types from './types'


export const actions = {
  studentListRequest: queryParams => ({
    type: types.STUDENT_LIST_REQUEST,
    payload: queryParams
  }),

  studentListFailure: error => ({
    type: types.STUDENT_LIST_FAILURE,
    payload: error
  }),

  studentListSuccess: (list, queryParams) => ({
    type: types.STUDENT_LIST_SUCCESS,
    payload: {
      result: list,
      queryParams
    }
  }),
}

export default actions
