import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogTitleWithCloseIcon from '../../commons/DialogTitleWithCloseIcon'

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function SLDialog(props) {
  return (
    <Dialog onClose={props.handleClose} aria-labelledby="sl-dialog-title" open={props.open}>
      <DialogTitleWithCloseIcon id="sl-dialog-title" onClose={props.handleClose}>
        Supervisionslevel
      </DialogTitleWithCloseIcon>
      <DialogContent dividers>
        <ol start={0}>
          <li>
            {'\n'}Ich würde den Studierenden bei dieser Tätigkeit <span
            style={{ fontWeight: 'bold' }}>nicht zuschauen</span> lassen
          </li>
          <br/>
          <li>
            Ich würde den Studierenden diese Tätigkeit <span style={{ fontWeight: 'bold' }}>nur beobachten</span> lassen
          </li>
          <br/>
          <li>
            Ich würde den Studierenden diese Tätigkeit mit <span style={{ fontWeight: 'bold' }}>vollständiger direkter Supervision</span> bzw.
            der <span
            style={{ fontWeight: 'bold' }}>Möglichkeit zum direkten Eingreifen</span> übernehmen
            lassen
          </li>
          <br/>
          <li>
            Ich würde den Studierenden diese Tätigkeit unter <span style={{ fontWeight: 'bold' }}>räumlich entfernter Supervision mit direkter Erreichbarkeit und Kontrolle des Ergebnisses</span> ausführen
            lassen
          </li>
          <br/>
          <li>
            Ich würde den Studierenden diese Tätigkeit unter <span style={{ fontWeight: 'bold' }}>räumlich entfernter Supervision ohne direkte Erreichbarkeit</span> ausführen
            lassen
          </li>
          <br/>
          <li>
            Ich würde dem Studierenden zutrauen, diese Tätigkeit unter <span
            style={{ fontWeight: 'bold' }}>voller Verantwortung ohne Supervision durchzuführen und andere in dieser Tätigkeit anzuführen und zu supervidieren</span>
          </li>
        </ol>
      </DialogContent>
    </Dialog>
  )
}
