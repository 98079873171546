import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'


const NotFound = () => (
  <div style={{ padding: 24 }}>
    <Typography variant="body1">In Entwicklung...</Typography>
  </div>
)

export default NotFound
