import { IconButton, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';


const useStyles = makeStyles(theme => ({
  content: {
    '& ul': {
      margin: 0
    }
  }
}))

export default function InfoDialog({ title, content, tooltip }) {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const iconButton = (
    <IconButton aria-label="info" onClick={handleOpen}>
      <InfoIcon />
    </IconButton>
  )

  return (
    <>
      { tooltip ?
      <Tooltip title={tooltip}>
        { iconButton }
      </Tooltip>
      : iconButton }
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="info-dialog-title"
      >
        <DialogTitle id="info-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText className={classes.content}>
            {content.__html ? <span dangerouslySetInnerHTML={content}/> : content}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
    )
}