import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import grey from '@material-ui/core/colors/grey';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    boxShadow: 'unset',
    borderRadius: '4px',
    border: '1px solid #efefef'
  },
  avatar: {
    backgroundColor: props => props.avatarColor || theme.palette.primary.light,
    color: props => props.avatarColor ? theme.palette.primary.dark : 'default'
  },
  closeButton: {
    color: grey[300]
  }
}))

const ChartCard = props => {
  const { children, graphId, title, numberOfItems, avatar, onClose } = props
  const classes = useStyles(props)

  const [visible, setVisible] = useState(true)

  const titleElement = <div>{title}</div>
  const subheaderElement = numberOfItems && <div>Zeitlicher Entwicklungsverlauf mit <strong>{numberOfItems} {numberOfItems > 1 ? 'Beobachtungen' : 'Beobachtung'}</strong></div>

  const handleClose = () => {
    setVisible(false)
    onClose && onClose(graphId)
  }

  if (!visible) {
    return null
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={titleElement}
        subheader={subheaderElement}
        avatar={
          avatar &&
          <Avatar aria-label="chart-type" className={classes.avatar}>
            {avatar}
          </Avatar>
        }
        action={
          <Tooltip title="Block ausblenden">
            <IconButton aria-label="close" onClick={handleClose} size="small" className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

export default ChartCard