import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import React from 'react';


const styles = theme => ({
  competencyTitle: {
    alignSelf: 'center'
  },
  competencyTitleText: {
    textAlign: 'center',
    fontWeight: 500,
    whiteSpace: 'pre-line',
    fontSize: '.8rem',
  }
})

const Row = props => {
  const { classes, competency, children } = props
  return (
    <Grid item xs={12}>
      <Grid container direction={'row'}>
        <Grid item xs={2} className={classes.competencyTitle}>
          <Typography variant={'caption'} className={classes.competencyTitleText}>{competency.title}</Typography>
        </Grid>
        {children}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Row)
