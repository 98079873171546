import { colors, IconButton, Paper, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';

import { recordActions } from '../../ducks/records';
import { selectVisibleValidationErrors } from '../../ducks/records/reducers';
import ManifestationScale from './ManifestationScale';

const styles = theme => ({
  competencyBlock: {
    backgroundColor: props => props.competency.color,
    padding: '15px 15px 0',
    borderRadius: '4px',
    '&:not(:last-child)': {
      marginBottom: '10px',
    },
  },
  competencyTitle: {
    paddingRight: '20px',
  },
  sliderPaper: {
    padding: '5px 95px',
    paddingTop: '20px',
    position: 'relative',
  },
  boundary: {
    borderRight: '1px solid #7b7b7b',
  },
  resetButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  hasErrors: {
    border: '2px solid',
    borderColor: colors.red[900],
  },
  noValueBlock: {
    opacity: 0.5,
  },
  description: {
    padding: '10px 0',
    '& ul': {
      margin: '0'
    }
  }
})

const SocialCompetency = props => {
  const { classes, competency, select, disabled, recordValidationErrors } = props
  const storedValue = competency.value === undefined ? null : competency.value
  const hasError = recordValidationErrors.find(error => error.property === 'competency' && error.propertyId === competency.id)
  return (
    <Grid container item className={clsx(classes.competencyBlock, {[classes.noValueBlock]: disabled && storedValue === null})}>
      <Grid item xs={3} className={classes.competencyTitle}>
        <Typography color="primary" variant="h6"><strong>{competency.title}</strong></Typography>
        <Typography variant="body2">{competency.description}</Typography>
        <Typography variant="body2"><i>{competency.help}</i></Typography>
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={12}>
          <Paper className={clsx(classes.sliderPaper, {[classes.hasErrors]: hasError})} elevation={(disabled && storedValue === null) ? 0 : 1}>
            <IconButton className={classes.resetButton} color="primary" disabled={disabled || storedValue === null} onClick={() => select({
              competencyId: competency.id,
              value: null,
            })}>
              <Tooltip title="Skala zurücksetzen">
                <SettingsBackupRestoreIcon/>
              </Tooltip>
            </IconButton>
            {competency.scale === 'manifestation' ? <ManifestationScale disabled={disabled} value={storedValue} onChange={(event, value) => select({
              competencyId: competency.id,
              value
            })}/> : null}
          </Paper>
        </Grid>
        <Grid container item xs={12}>
          {competency.scale_descriptions.map(
            (descriptions, index, arr) => <Fragment key={index}><Grid item xs={5} className={classes.description}>
              {descriptions.map((description, j) =>
                <Typography variant="caption" key={`${index}-${j}`}>
                  <strong>{description.heading}</strong><br/><span dangerouslySetInnerHTML={description.content}/>
                </Typography>
              )}
            </Grid>
            { index < arr.length - 1 ? <><Grid item xs={1} className={classes.boundary}/><Grid item xs={1}/></> : null}
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ record }) => ({
  recordValidationErrors: selectVisibleValidationErrors(record)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      select: recordActions.selectBehaviour
    },
    dispatch
  )


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SocialCompetency))
