import types from './types'


export const actions = {
  observationAssignmentsRequest: () => ({
    type: types.OBSERVATION_ASSIGNMENTS_FETCH_REQUEST
  }),

  observationAssignmentsFailure: error => ({
    type: types.OBSERVATION_ASSIGNMENTS_FETCH_FAILURE,
    payload: error
  }),

  observationAssignmentsSuccess: observationAssignments => ({
    type: types.OBSERVATION_ASSIGNMENTS_FETCH_SUCCESS,
    payload: observationAssignments
  }),

  setActive: observationAssignment => ({
    type: types.OBSERVATION_ASSIGNMENTS_SET_ACTIVE,
    payload: observationAssignment
  })
}

export default actions