import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IfasLogo from '../../assets/images/ifas_logo.png';
import FakultaetLogo from '../../assets/images/mfm_logo.png';
import { userOperations } from '../../ducks/users';

const styles = theme => ({
  background: {
    minHeight: '100%',
    background: `linear-gradient(to right bottom, ${
      theme.palette.primary.main
      }, ${theme.palette.primary.light})`
  },
  margin: {
    margin: theme.spacing(1)
  },
  card: {
    maxWidth: '1000px',
    margin: '20px',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    },
  },
  cardContent: {
    margin: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center'
  },
  logos: {
    borderTop: '1px solid #ccc',
    marginTop: '20px',
    paddingTop: '20px',
  },
  logo: {
    maxWidth: '250px',
    width: '35%',
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  lightButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.primary.light
  },
  disclaimerBox: {
    padding: '20px',
    background: '#eee',
  },
  disclaimerButton: {
    margin: '20px 0',
    textTransform: 'none',
  }
})

function Disclaimer(props) {
  const { classes, signOut, addMinimalPermission, user, history } = props

  const isLoggedIn = !!user;
  function handleSignOut() {
    signOut()
    redirectToLogin()
  }

  function redirectToLogin() {
    history.push('/login')
  }

  function handleDisclaimerAcceptance() {
    addMinimalPermission()
    handleSignOut()
  }

  if (!isLoggedIn) {
    return <Grid
      className={classes.background}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h4" component="h1" gutterBottom>Willkommen bei myEPAs</Typography>
          <Typography gutterBottom>Bitte loggen Sie sich ein!</Typography>
          <Button variant="contained" color="primary" onClick={redirectToLogin}>Zum Login</Button>
        </CardContent>
      </Card>
    </Grid>
  }

  return <Grid
    className={classes.background}
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
  >
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column">
          <Typography variant="h4" component="h1" gutterBottom>Willkommen bei myEPAs</Typography>
          <Typography variant="body1" paragraph>
            Mit "myEPAs" (EPA = <strong>Entrustable Professional Activity</strong>) können Beobachter:innen die Performance von Studierenden anhand der 13 Core-EPAs bewerten (Fremdeinschätzung).
            Weitere Infos sind auf der Webseite des <a href='https://medicampus.uni-muenster.de/ccel/projects/my-epas/' target='_blank' rel='noopener noreferrer'>Kompetenzzentrums E-Learning</a> zu finden.
          </Typography>
          <Typography variant="body1" paragraph>
            Um die Fremdeinschätzung einzusehen, die <strong>über Sie erstellt wurden</strong>, lesen Sie bitte folgende Information und nehmen diese anschließend zur Kenntnis. Danach erhalten Sie automatisch das entsprechende Recht und müssen sich <strong>neu einloggen</strong>.
          </Typography>
          <Paper className={classes.disclaimerBox}>
            <Typography variant="body1" paragraph>
              Die Fremdeinschätzungen (und später auch Ihre Selbsteinschätzungen) werden in einem <strong>Dashboard</strong> angezeigt, wo Sie zunächst eine Übersicht über alle EPAs und einen zeitlichen Verlauf über Einzel-EPAs einsehen können. Jeder Punkt im zeitlichen Verlauf entspricht einer Fremdeinschätzung. Durch einen Klick auf den Punkt können Sie Details zu der Fremdeinschätzung einsehen (Kompetenzebenen und Text-Feedback).
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>Bitte scheuen Sie sich nicht, bei inhaltlichen Rückfragen oder sonstigem Beratungsbedarf, Helmut Ahrens (ahrensh@uni-muenster.de) vom IfAS zu kontaktieren.</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              Bei technischen Fragen hilft Ihnen Friedrich Pawelka (pawelka@uni-muenster.de) vom IfAS weiter.
            </Typography>
          </Paper>
          <Button variant="contained" color="primary" onClick={handleDisclaimerAcceptance} className={classes.disclaimerButton}>
            Ich habe die Informationen zum EPA-Dashboard verstanden und auch, an wen ich mich bei Fragen wenden kann.
          <br/>Bitte das Recht zum Aufrufen des Dashboards erteilen.
          </Button>
          <Typography variant="body1" paragraph>
            <i>Hinweis: Falls Sie Dozent:in oder Tutor:in sind und Fremdeinschätzungen erstellen wollen, wenden Sie sich bitte an die Mitarbeiter:innen des IfAS. Wenden Sie sich im Zweifel an Friedrich Pawelka (pawelka@uni-muenster.de).</i>
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={'space-between'}
          className={classes.logos}
        >
          <a
            href="https://www.medizin.uni-muenster.de/"
            target="_blank"
            rel="noopener noreferrer"
            title="Website der medizinischen Fakultät Münster"
            className={classes.logo}
          >
            <img
              src={FakultaetLogo}
              alt={'Logo der Medizinischen Fakultät der Universität Münster'}
              style={{maxWidth: '100%'}}
            />
          </a>
          <a
            href="https://medicampus.uni-muenster.de/ifas.html"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.logo}
            title="Website des IfAS"
          >
            <img
              src={IfasLogo}
              alt={'Logo des Instituts für Ausbildung und Studienangelegenheiten'}
              style={{maxWidth: '100%'}}
            />
          </a>
        </Grid>
      </CardContent>
    </Card>
    {user ?
      <Button onClick={handleSignOut} className={clsx(classes.margin, classes.lightButton)}>Abmelden</Button> :
      <Button onClick={redirectToLogin} className={clsx(classes.margin, classes.lightButton)}>Zum Login</Button>
    }
  </Grid>
}

const mapStateToProps = ({user}) => ({
  user: user.user,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    signOut: userOperations.signOut,
    addMinimalPermission: userOperations.addMinimalPermission,
  },
  dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Disclaimer))
