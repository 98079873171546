import types from './types'


const INITIAL_STATE = {
  students: [],
  queryParams: null,
  error: '',
  loading: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.STUDENT_LIST_REQUEST:
      return { ...state, loading: true, error: '', queryParams: {...action.payload} }
    case types.STUDENT_LIST_SUCCESS:
      return { ...state, loading: false, students: prepareStudentsForSelection(action.payload.result), queryParams: {...action.payload.queryParams} }
    case types.STUDENT_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

const prepareStudentsForSelection = students => students.map(student => {
  let label = `${student.firstName} ${student.lastName}`;
  if (student.semester) {
    label += ` (${student.semester})`
  }
  return { value: student, label }
})

export default reducer