import './index.css';
import 'abortcontroller-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';

import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import { ConnectedRouter, push } from 'connected-react-router';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import config from './config/config';
import App from './containers/app';
import { userOperations } from './ducks/users';
import redux, { history } from './redux';


const target = document.querySelector('#root')

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0f515c',
      dark: '#33444d'
    },
    secondary: {
      main: orange[800]
    },
    paper: {
      main: '#fff'
    }
  }
})
const { breakpoints } = defaultTheme

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      caption: {
        lineHeight: 1.45,
        fontSize: '0.7rem',
        letterSpacing: '0rem',
        [breakpoints.down('md')]: {
          fontSize: '0.675rem',
          lineHeight: 1.3
        }
      },
      body1: {
        [breakpoints.down('md')]: {
          fontSize: '0.82rem',
          letterSpacing: '0rem',
          lineHeight: 1.2
        }
      }
    }
  }
}
const reduxObj = redux()

let url = window.location.href
if ((url.includes('code') || url.includes('access_token'))) {
  const authFlow = url.includes('code') ? 'code' : 'token';
  reduxObj.store.dispatch(userOperations.authenticateWithUrl(url, authFlow))
}
let lastLocation = localStorage.getItem(`${config.localStoragePrefix}location`)
if (lastLocation) {
  localStorage.removeItem(`${config.localStoragePrefix}location`)
  reduxObj.store.dispatch(push(lastLocation))
}


render(
  <MuiThemeProvider theme={theme}>
    <Provider store={reduxObj.store}>
      <PersistGate persistor={reduxObj.persistor} loading={null}>
        <ConnectedRouter history={history}>
          <App/>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,
  target
)

export default reduxObj.store
