import React from 'react';
import { XAxis } from 'recharts';

const RecordXAxis = props => {
  return (
    <XAxis dataKey="title" name="Fall und Zeit" interval={0} tick={CustomizedAxisTick}/>
  )
}


const CustomizedAxisTick = props => {
  const { x, y, payload } = props
  const values = payload.value
    .split('\n')
    .map((value, index) => <text key={index} dy={16} fontSize={10} textAnchor="end" fill="#666" transform={`rotate(-10), translate(15,${ (index - 1) * 10})`}>{value}</text>)
  return (
    <g transform={`translate(${x},${y})`}>
      {values}
    </g>
  )
}

export default RecordXAxis