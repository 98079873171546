import { userOperations } from './users'
import store from '../index'
import config from '../config/config'

const controller = new AbortController()
const { signal } = controller

const extFetch = (url, args) => {
  return fetch(url, { ...args, signal })
    .then(handleErrors)
}

const handleErrors = (response) => {
  if (!response.ok) {
    const {status, statusText} = response
    switch (status) {
      case 401:
        let currentLocation = store.getState().router.location.pathname
        localStorage.setItem(`${config.localStoragePrefix}location`, currentLocation)
        controller.abort()
        store.dispatch(userOperations.signOut())
        store.dispatch(userOperations.authenticateWithMedforge())
        throw new Error(statusText)

      default:
        throw new Error(statusText)
    }
  }
  return response
}

export default extFetch