import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import clsx from 'clsx';
import React from 'react';

const styles = theme => ({
  card: {
    height: '100%',
    boxShadow: 'unset',
    borderRadius: '4px',
    lineHeight: 0,
    marginRight: 4,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      marginRight: 0
    }
  },
  cardSelected: {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  cardContent: {
    padding: theme.spacing(0.75)

  },
  cardActionArea: {
    height: '100%'
  }
})

const Item = props => {
  const theme = useTheme()
  const { classes, color, title, isSelected } = props
  return (
    <Grid item xs={3}>
      <Card
        className={clsx(classes.card, { [classes.cardSelected]: isSelected })}
        style={{
          color: isSelected ? '#e3e3e3' : 'black',
          backgroundColor: isSelected ?
            theme.palette.primary.main : color
        }}>

        <CardContent className={classes.cardContent}>
          <Typography
            variant={'caption'} style={{
            whiteSpace: 'pre-line'
          }}>{title}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default withStyles(styles)(Item)
