import React from 'react';
import { connect } from 'react-redux';

import EpaForm from './EpaForm';
import MiniCexForm from './MiniCexForm';
import SocialCompetenciesForm from './SocialCompetenciesForm';

class FormContainer extends React.Component {
  render() {
    const { form } = this.props
    if (!form) {
      return null;
    }
    return (
      form.id === 14 ? <SocialCompetenciesForm/> :
      form.id === 15 ? <MiniCexForm/> :
      <EpaForm/>
    )
  }
}

const mapStateToProps = ({ record }) => ({
  form: record.selectedEpa,
})

export default connect(
  mapStateToProps
)(FormContainer)